import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

import { Button } from '@headway/helix/Button';
import { Group } from '@headway/helix/Group';
import { IconUpload } from '@headway/helix/icons/Upload';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { trackEvent } from '@headway/shared/utils/analytics';

import { IEmailDetails, trackCTAEvents } from '../hooks/extole';
import ReferralEmailSideSheet from './ReferralEmailSideSheet';

const ReferralShareSideSheet = ({
  shareLink,
  advocateCode,
  accessToken,
  campaignId,
  emailDetails,
  programLabel,
  referralId,
}: {
  shareLink: string;
  advocateCode: string;
  accessToken: string;
  programLabel: string;
  campaignId?: string;
  emailDetails?: IEmailDetails;
  referralId: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [referralLinkButtonText, setReferralLinkButtonText] =
    useState('Copy link');
  const clipboard = useClipboard({
    copiedTimeout: 1000,
  });

  return (
    <>
      <Button
        variant="secondary"
        onPress={() => {
          setIsOpen(true);
          trackCTAEvents({
            eventName: 'share',
            token: accessToken,
            source: 'referral_share_modal',
            channel: 'direct',
            programLabel,
            referralId,
          });
        }}
      >
        <div className="flex flex-row justify-center gap-1">
          <IconUpload />
          <span>Share your referral link</span>
        </div>
      </Button>
      <Modal
        title="Share your referral link"
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
      >
        <ModalContent>
          <Group>
            <TextField
              name="referralLink"
              defaultValue={shareLink}
              label="Share your referral link"
              readonly
            />
            <Button
              type="button"
              variant="secondary"
              size="large"
              disabled={!shareLink}
              onPress={() => {
                clipboard.copy(shareLink);
                setReferralLinkButtonText('Copied');
                trackCTAEvents({
                  eventName: 'share',
                  token: accessToken,
                  source: 'referral_share_modal',
                  channel: 'link',
                  programLabel,
                  referralId,
                });
              }}
              // This is how LaunchDarkly tracks the button click as a conversion event
              // for the experiment.
              data-ld-target="copy-referral-link"
            >
              {referralLinkButtonText}
            </Button>
          </Group>
          <div className="mx-auto flex flex-row justify-center p-2">
            <ReferralEmailSideSheet
              advocateCode={advocateCode}
              accessToken={accessToken}
              campaignId={campaignId}
              programLabel={programLabel}
              emailDetails={emailDetails}
              referralId={referralId}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            type="submit"
            variant="primary"
            size="large"
            onPress={() => {
              setIsOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReferralShareSideSheet;
