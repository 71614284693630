import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { REFERRAL_SOURCE } from '@headway/shared/constants/referrals';

import {
  postReferProviderEvent,
  postShareEvent,
  trackCTAEvents,
} from '../hooks/extole';

const formSchema = Yup.object({
  referralFirstName: Yup.string().required('Required'),
  referralLastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneNumber: Yup.string().required('Required'),
  licenseType: Yup.string().required('Required'),
  licenseState: Yup.string().required('Required'),
});

const ReferralSideSheet = ({
  advocateCode,
  accessToken,
  campaignId,
  programLabel,
  referralId,
}: {
  advocateCode: string;
  accessToken: string;
  campaignId: string;
  programLabel: string;
  referralId: string;
}) => {
  if (!advocateCode || !accessToken) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = async ({
    referralFirstName,
    referralLastName,
    email,
    phoneNumber,
    licenseType,
    licenseState,
  }: {
    referralFirstName: string;
    referralLastName: string;
    email: string;
    phoneNumber: string;
    licenseType: string;
    licenseState: string;
  }) => {
    await Promise.all([
      postShareEvent({
        token: accessToken,
        advocateCode,
        email,
      }),
      postReferProviderEvent({
        token: accessToken,
        advocateCode,
        email,
        firstName: referralFirstName,
        lastName: referralLastName,
        phoneNumber,
        licenseType,
        licenseState,
        campaignId,
        programLabel,
      }),
    ]);
  };

  return (
    <>
      <Button
        type="button"
        variant="primary"
        size="large"
        onPress={() => {
          setIsOpen(true);
          trackCTAEvents({
            eventName: 'lead_form_clicked',
            token: accessToken,
            channel: 'direct',
            programLabel,
            referralId,
          });
        }}
      >
        Invite a provider directly
      </Button>
      <Modal
        variant="sidesheet"
        title="Invite a provider"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <Formik
          validationSchema={formSchema}
          initialValues={{
            referralFirstName: '',
            referralLastName: '',
            email: '',
            phoneNumber: '',
            licenseType: '',
            licenseState: '',
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <>
              <ModalContent>
                <Form id="Referral-intake">
                  <BodyText>
                    One of our practice consultants will email your referral to
                    schedule an intro meeting.
                  </BodyText>
                  <div className="flex flex-col gap-4">
                    <FormControl
                      key="referralFirstName"
                      component={TextField}
                      name="referralFirstName"
                      label="Referral first name"
                    />
                    <FormControl
                      key="referralLastName"
                      component={TextField}
                      name="referralLastName"
                      label="Referral last name"
                    />
                    <FormControl
                      key="email"
                      component={TextField}
                      name="email"
                      label="Email address"
                    />
                    <FormControl
                      key="phoneNumber"
                      component={PhoneNumberField}
                      name="phoneNumber"
                      label="Phone number"
                    />
                    <FormControl
                      key="licenseType"
                      component={TextField}
                      name="licenseType"
                      label="License type"
                    />
                    <FormControl
                      key="licenseState"
                      component={TextField}
                      name="licenseState"
                      label="State of license"
                    />
                  </div>
                </Form>
              </ModalContent>
              <ModalFooter>
                <Button
                  form="Referral-intake"
                  type="submit"
                  variant="primary"
                  size="large"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReferralSideSheet;
