import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { LOGIN_CREDENTIALS_COLLECTION_TASK_TYPES } from '@headway/shared/constants/providerTaskType';
import { PASSWORD_COLLECTION_PROVIDER_TASKS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { LoginCredentialsTaskModal } from 'views/InsuranceStatus/ProviderTasks/LoginCredentialsTaskModal';

const GenericProviderTaskModal = ({
  activeProviderTask,
  onDismiss,
}: {
  activeProviderTask: ProviderTaskRead;
  onDismiss: () => void;
}) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      title={`Action required: ${activeProviderTask?.title}`}
    >
      <ModalContent>
        <BodyText>
          <div className="grid gap-y-4">
            <div>{activeProviderTask?.description}</div>
            <div>{<b>Here's what to do: </b>}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(activeProviderTask?.statusDescription || ''),
              }}
            ></div>
          </div>
        </BodyText>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          variant="primary"
          href={
            'https://headway.co/contact?primaryIssueType=pit_credentialing___payer_networks&secondaryIssueType=credentialing_documentation&tags=cred_docs_url'
          }
          target="_blank"
        >
          Submit information to Headway
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};

export const ProviderTaskModal = ({
  activeProviderTask,
  onDismiss,
}: {
  activeProviderTask: ProviderTaskRead | undefined;
  onDismiss: () => void;
}) => {
  const isPasswordCollectionEnabled = useFlag(
    PASSWORD_COLLECTION_PROVIDER_TASKS,
    false
  );
  if (!activeProviderTask) {
    return null;
  }
  return (
    <>
      {isPasswordCollectionEnabled &&
      activeProviderTask.taskType &&
      LOGIN_CREDENTIALS_COLLECTION_TASK_TYPES.has(
        activeProviderTask.taskType
      ) ? (
        <LoginCredentialsTaskModal
          activeProviderTask={activeProviderTask}
          onDismiss={onDismiss}
        />
      ) : (
        <GenericProviderTaskModal
          activeProviderTask={activeProviderTask}
          onDismiss={onDismiss}
        />
      )}
    </>
  );
};
