import { Email } from '@mui/icons-material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { IconButton } from '@headway/helix/IconButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextArea } from '@headway/helix/TextArea';
import { TextField } from '@headway/helix/TextField';
import { toasts } from '@headway/helix/Toast';
import { REFERRAL_SOURCE } from '@headway/shared/constants/referrals';

import {
  consentToMarketing,
  IEmailDetails,
  sendReferralEmail,
  trackCTAEvents,
} from '../hooks/extole';

const formSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required'),
  personalNote: Yup.string(),
});

const ReferralEmailSideSheet = ({
  advocateCode,
  accessToken,
  campaignId,
  programLabel,
  emailDetails,
  referralId,
}: {
  advocateCode?: string;
  accessToken?: string;
  campaignId?: string;
  programLabel: string;
  emailDetails?: IEmailDetails;
  referralId: string;
}) => {
  if (!advocateCode || !accessToken) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async ({
    email,
    personalNote,
    marketingConsent,
  }: {
    email: string;
    personalNote: string;
    marketingConsent: boolean;
  }) => {
    try {
      await sendReferralEmail({
        email,
        message: personalNote,
        advocateCode,
        token: accessToken,
        campaignId,
      });
      consentToMarketing({
        token: accessToken,
        consent: marketingConsent,
      });

      setIsOpen(false);
      toasts.add('We’ve received your referral.', {
        variant: 'positive',
        // UNCOMMENT WHEN REFERRAL SUMMARY IS AVAILABLE
        // actionLabel: 'See referrals',
        // onAction: () => {},
      });
    } catch (err) {
      toasts.add('There was an issue sending your referral.', {
        variant: 'negative',
      });
    }
  };

  return (
    <>
      <IconButton
        type="button"
        variant="transparent"
        size="medium"
        onPress={() => {
          setIsOpen(true);
          trackCTAEvents({
            eventName: 'email_referral_clicked',
            token: accessToken,
            channel: 'direct',
            programLabel,
            referralId,
          });
        }}
      >
        <Email />
      </IconButton>
      <Modal
        variant="sidesheet"
        title="Email a provider"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <Formik
          validationSchema={formSchema}
          initialValues={{
            email: '',
            personalNote:
              emailDetails?.message ??
              `I love using Headway to run my best practice — and I think you will too! If you join using my link, you’ll get $100 after your first session. These are definitely rewards worth sharing. \n\nHeadway helped me get credentialed fast. Since then, I’ve been accepting insurance with ease, earning enhanced rates paid bi-weekly, and running my entire practice from a single place without ever paying a membership fee. I highly recommend it. \n\nSimply use my link to get started.`,
            marketingConsent: true,
          }}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting }) => {
            return (
              <>
                <ModalContent>
                  <Form id="Referral-email-intake">
                    <BodyText>
                      Email your referral and invite them to join Headway.
                    </BodyText>
                    <FormControl
                      autoFocus={true}
                      component={TextField}
                      name="email"
                      label="Send to (Email address)"
                    />
                    <FormControl
                      component={TextArea}
                      optionalityText="Optional"
                      label="Personal note"
                      name="personalNote"
                      value={values.personalNote}
                    />
                    <FormControl component={Checkbox} name="marketingConsent">
                      I agree to receive marketing communications from Headway.
                    </FormControl>
                  </Form>
                </ModalContent>
                <ModalFooter>
                  <Button
                    form="Referral-email-intake"
                    type="submit"
                    variant="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Send email
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ReferralEmailSideSheet;
