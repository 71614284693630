import { colors as foundationColors } from './tokens/colors';
import {
  FONT_FAMILY_BODY,
  FONT_FAMILY_HEADLINE,
  FONT_SIZE_BASE_4,
  FONT_SIZE_BASE_5,
  FONT_SIZE_BASE_7,
  FONT_SIZE_BASE_9,
  FONT_SIZE_BASE_11,
  FONT_SIZE_BASE_13,
  FONT_SIZE_BASE_14,
  FONT_SIZE_BASE_15,
  FONT_SIZE_BASE_18,
  FONT_SIZE_BASE_20,
  FONT_WEIGHT_BODY_MEDIUM,
  FONT_WEIGHT_BODY_REGULAR,
  FONT_WEIGHT_HEADLINE_REGULAR,
  LINE_HEIGHT_BASE_3,
  LINE_HEIGHT_BASE_4,
  LINE_HEIGHT_BASE_6,
  LINE_HEIGHT_BASE_7,
  LINE_HEIGHT_BASE_8,
  LINE_HEIGHT_BASE_9,
  LINE_HEIGHT_BASE_10,
  LINE_HEIGHT_BASE_14,
  LINE_HEIGHT_BASE_17,
} from './tokens/typography';

const breakpoints = {
  wide: 1280,
  desktop: 1044,
  tablet: 768,
  phone: 480,
} as const;

const DESKTOP_BREAKPOINT = 1024;
// @deprecated: use __futureMedia instead
const legacyMedia = {
  mobile: `@media screen and (max-width: ${DESKTOP_BREAKPOINT}px)`,
  desktop: `@media screen and (min-width: ${DESKTOP_BREAKPOINT + 1}px)`,
};
// @deprecated: use __futureMedia instead
const legacyMediaQuery = {
  mobile: `(max-width: ${DESKTOP_BREAKPOINT}px)`,
  desktop: `(min-width: ${DESKTOP_BREAKPOINT + 1}px)`,
};

const media = {
  below(breakpoint: keyof typeof breakpoints): string {
    return `@media (max-width: ${breakpoints[breakpoint] - 1}px)`;
  },

  above(breakpoint: keyof typeof breakpoints): string {
    return `@media (min-width: ${breakpoints[breakpoint]}px)`;
  },
  wide: `@media screen and (min-width: ${breakpoints.wide}px)`,
  desktop: `@media screen and (min-width: ${
    breakpoints.desktop
  }px) and (max-width: ${breakpoints.wide - 1}px)`,
  tablet: `@media screen and (min-width: ${
    breakpoints.tablet
  }px) and (max-width: ${breakpoints.desktop - 1}px)`,
  phone: `@media screen and (max-width: ${breakpoints.phone - 1}px)`,
};

// Design tokens for things like fonts, tap size/spacing, etc.
const responsive = {
  mobile: `@media screen and (max-width: ${breakpoints.tablet - 1}px)`,
};

const fontWeight = {
  regular: 400,
  bold: 700,
} as const;

const typography = {
  headline: {
    H2: {
      fontFamily: FONT_FAMILY_HEADLINE,
      fontSize: FONT_SIZE_BASE_20,
      lineHeight: LINE_HEIGHT_BASE_17,
      fontWeight: FONT_WEIGHT_HEADLINE_REGULAR,
      letterSpacing: '-0.02em',
    },
    H3: {
      fontFamily: FONT_FAMILY_HEADLINE,
      fontSize: FONT_SIZE_BASE_18,
      lineHeight: LINE_HEIGHT_BASE_14,
      fontWeight: FONT_WEIGHT_HEADLINE_REGULAR,
      letterSpacing: '-0.02em',
    },
    H4: {
      fontFamily: FONT_FAMILY_HEADLINE,
      fontSize: FONT_SIZE_BASE_15,
      lineHeight: LINE_HEIGHT_BASE_10,
      fontWeight: FONT_WEIGHT_HEADLINE_REGULAR,
      letterSpacing: '-0.02em',
    },
    H5: {
      fontFamily: FONT_FAMILY_HEADLINE,
      fontSize: FONT_SIZE_BASE_14,
      lineHeight: LINE_HEIGHT_BASE_9,
      fontWeight: FONT_WEIGHT_HEADLINE_REGULAR,
      letterSpacing: '-0.02em',
    },
  },
  content: {
    'page-heading': {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_13,
      lineHeight: LINE_HEIGHT_BASE_10,
      fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      letterSpacing: '-0.02em',
    },
    'section-heading': {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_11,
      lineHeight: LINE_HEIGHT_BASE_8,
      fontWeight: FONT_WEIGHT_BODY_REGULAR,
      letterSpacing: '-0.01em',
    },
    get 'section-heading/medium'() {
      return {
        ...this['section-heading'],
        fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      };
    },
    body: {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_7,
      lineHeight: LINE_HEIGHT_BASE_6,
      fontWeight: FONT_WEIGHT_BODY_REGULAR,
      letterSpacing: 'normal',

      [responsive.mobile]: {
        fontSize: '17px',
      },
    },
    get 'body/medium'() {
      return {
        ...this.body,
        fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      };
    },
    get regular() {
      return this.body;
    },
    get medium() {
      return this['body/medium'];
    },
    'body-large': {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_9,
      lineHeight: LINE_HEIGHT_BASE_7,
      fontWeight: FONT_WEIGHT_BODY_REGULAR,
      letterSpacing: 'normal',
    },
    get 'body-large/medium'() {
      return {
        ...this['body-large'],
        fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      };
    },
    'body-small': {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_5,
      lineHeight: LINE_HEIGHT_BASE_4,
      fontWeight: FONT_WEIGHT_BODY_REGULAR,
      letterSpacing: 'normal',

      [responsive.mobile]: {
        fontSize: '15px',
      },
    },
    get 'body-small/medium'() {
      return {
        ...this['body-small'],
        fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      };
    },
    caption: {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: FONT_SIZE_BASE_4,
      lineHeight: LINE_HEIGHT_BASE_3,
      fontWeight: FONT_WEIGHT_BODY_REGULAR,
      letterSpacing: 'normal',
    },
    get 'caption/medium'() {
      return {
        ...this.caption,
        fontWeight: FONT_WEIGHT_BODY_MEDIUM,
      };
    },
  },
  hyperlink: {
    textDecoration: 'underline !important',
    textUnderlineOffset: '2px',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
  },
};

const legacyTypography = {
  /**
   * @deprecated
   * Use `typography.headline` variants instead
   */
  get brand() {
    return {
      header: typography.headline.H3,
      section: typography.headline.H4,
    };
  },
  /**
   * @deprecated
   * Use `typography.content` variants instead
   */
  get header() {
    return typography.content['page-heading'];
  },
  /**
   * @deprecated
   * Use `typography.content` variants instead
   */
  get sectionHeader() {
    return typography.content['section-heading/medium'];
  },
  /**
   * @deprecated
   * Use `typography.content` variants instead
   */
  get list() {
    return typography.content['body-large/medium'];
  },

  get body() {
    return {
      regular: typography.content['body'],
      medium: typography.content['body/medium'],
    };
  },
  /**
   * @deprecated
   * Use `typography.content` variants instead
   */
  get subbody() {
    return {
      regular: typography.content['body-small'],
      medium: typography.content['body-small/medium'],
    };
  },
  /**
   * @deprecated
   * Use `typography.content` variants instead
   */
  get caption() {
    return {
      regular: typography.content['caption'],
      medium: typography.content['caption/medium'],
    };
  },
  hyperlink: typography.hyperlink,
};

const legacyColor = {
  primary: {
    brandGreen: '#13AA65',
    blue: '#2C98EF',
    red: '#E32C02',
    yellow: '#FFC41D',
    brown: '#7E5C00',
  },
  hue: {
    lightGreen: '#E7FBF2',
    lightBlue: '#E8F4FD',
    lightSystemRed: '#FFF4F1',
    lightBrandRed: '#FFF0F9',
    lightYellow: '#FFF8EE',
  },
  system: {
    green: '#128756',
    black: '#212326',
    textBlack: '#353535',
    gray: '#717171',
    disabledGray: '#BEBEBE',
    borderGray: '#DADCE0',
    lightGray: '#EBEBEB',
    backgroundGray: '#F8F8F8',
    white: '#FFFFFF',
  },
} as const;

/**
 * Helix web color tokens.  This is mostly a re-export of the foundational color tokens
 * with some left out that are not used in the web theme. Prefer explicit but verbose mapping
 * here over some more clever mapping that might be harder to understand in the future.
 */
const color = {
  ...legacyColor,
  background: {
    primary: foundationColors.background['primary'],
    secondary: foundationColors.background['secondary'],
    tertiary: foundationColors.background['tertiary'],
    quartenary: foundationColors.background['quartenary'],
    disabled: foundationColors.background['disabled'],
    'compliance-light': foundationColors.background['compliance-light'],
    compliance: foundationColors.background['compliance'],
    'compliance-hover': foundationColors.background['compliance-hover'],
    'compliance-active': foundationColors.background['compliance-active'],
    selected: foundationColors.background['selected'],
    'selected-hover': foundationColors.background['selected-hover'],
    'selected-active': foundationColors.background['selected-active'],
    info: foundationColors.background['info'],
    'info-hover': foundationColors.background['info-hover'],
    warning: foundationColors.background['warning'],
    'warning-hover': foundationColors.background['warning-hover'],
    'danger-light': foundationColors.background['danger-light'],
    danger: foundationColors.background['danger'],
    'danger-hover': foundationColors.background['danger-hover'],
    'danger-active': foundationColors.background['danger-active'],
    'inverted-primary': foundationColors.background['inverted-primary'],
    'inverted-secondary': foundationColors.background['inverted-secondary'],
    'inverted-tertiary': foundationColors.background['inverted-tertiary'],
  },
  foreground: {
    primary: foundationColors.foreground['primary'],
    secondary: foundationColors.foreground['secondary'],
    disabled: foundationColors.foreground['disabled'],
    compliance: foundationColors.foreground['compliance'],
    'compliance-secondary': foundationColors.foreground['compliance-secondary'],
    'compliance-tertiary': foundationColors.foreground['compliance-tertiary'],
    info: foundationColors.foreground['info'],
    'info-secondary': foundationColors.foreground['info-secondary'],
    warning: foundationColors.foreground['warning'],
    'warning-secondary': foundationColors.foreground['warning-secondary'],
    danger: foundationColors.foreground['danger'],
    'danger-secondary': foundationColors.foreground['danger-secondary'],
    'inverted-primary': foundationColors.foreground['inverted-primary'],
  },
  border: {
    primary: foundationColors.border.primary,
    secondary: foundationColors.border.secondary,
    tertiary: foundationColors.border.tertiary,
    focus: foundationColors.border.focus,
    info: foundationColors.border.info,
    warning: foundationColors.border.warning,
    danger: foundationColors.border.danger,
    disabled: foundationColors.border.disabled,
  },
};

const spacing = {
  x1: '4px',
  x2: '8px',
  x3: '12px',
  x4: '16px',
  x5: '20px',
  x6: '24px',
  x7: '28px',
  x8: '32px',
  x9: '36px',
  x10: '40px',
  x11: '44px',
  x12: '48px',
} as const;

const elevation = {
  light: '0px 2px 16px rgba(0, 0, 0, 0.12)',
  medium: '0px 2px 8px rgba(0, 0, 0, 0.2)',
} as const;

const layers = {
  backdrop: 0,
  base: 10,
  modal: 20,
  popover: 30,
} as const;

const stack = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
} as const;

export const theme = {
  color: legacyColor,
  elevation,
  breakpoints,
  responsive,
  media: legacyMedia,
  mediaQuery: legacyMediaQuery,
  __futureMedia: media,
  spacing,
  stack,
  typography: legacyTypography,
  fontWeight,
  layers,
  reset: {
    '& :where(h1, h2, h3, h4, h5, h6)': {
      fontSize: 'unset',
      margin: 0,
    },
  },
};
