import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import React, { Key, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { IconChevronDown } from '@headway/helix/icons/ChevronDown';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Switch } from '@headway/helix/Switch';
import { theme } from '@headway/helix/theme';
import { HELIX_TOAST_DEFAULT_TIMEOUT_MS, toasts } from '@headway/helix/Toast';
import {
  MULTI_STATE_CREDENTIALING_SEARCH,
  PROVIDER_REFERRAL_MANAGEMENT,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
} from 'mutations/utils';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';
import {
  getCanUpdateShownInSearch,
  getProviderLicenseStatesWhereCanUpdateShownInSearch,
  getShownInSearch,
} from 'utils/shownInSearch';
import { PaginatedConcreteProviderEventRead } from 'utils/types';

import { useProvider } from '../../hooks';
import { isPendingReferralAppointment } from '../Calendar/events/util/events';
import { Task } from './Task';
import { TaskLoadingState } from './TasksToDo';
import { trackProviderTaskAnalyticsEvent } from './utils';

const taskCategory = 'REFERRAL';

const updateMatchingProviderLicenseStateWithShownInSearchValue = (
  pls: ProviderLicenseStateRead,
  updatedLicenses: ProviderLicenseStateRead[],
  shownInSearch: boolean
) => {
  const isAnUpdatedLicense = updatedLicenses.find(
    (updatedLicense) => updatedLicense.id === pls.id
  );

  if (isAnUpdatedLicense) {
    return {
      ...pls,
      shownInSearch,
    };
  } else {
    return pls;
  }
};

export const NewClientReferrals = () => {
  const isPayerLevelReferralsEnabled = useFlag('payerLevelReferrals', false);
  const isMscSearchEnabled = useFlag(MULTI_STATE_CREDENTIALING_SEARCH, false);
  const providerReferralManagementFlagEnabled = useFlag(
    PROVIDER_REFERRAL_MANAGEMENT,
    false
  );
  const AuthStore = useAuthStore();
  const toastCloseRef = useRef<(() => void) | null>();
  const provider = useProvider();
  const navigate = useNavigate();
  const [expandedButton, setExpandedButton] = useState(false);
  const displayLimit = 3;

  const fetchNewClientReferrals = useQuery(
    ['newClientReferrals'],
    async () =>
      ProviderEventApi.getEvents({
        provider_id: provider?.id,
        date_range_start: moment().toISOString(),
        date_range_end: moment().add(2, 'weeks').toISOString(),
        appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
        channels: Object.values(ProviderEventChannel).filter(
          (channel) =>
            channel !== ProviderEventChannel.PROVIDER_PORTAL &&
            channel !== ProviderEventChannel.EXTERNAL_CALENDAR
        ),
        event_types: [
          ProviderEventType.INTAKE_CALL,
          ProviderEventType.APPOINTMENT,
        ],
        expand_estimated_prices: false,
        order_by: 'created_on',
        order: 'desc',
      }) as Promise<PaginatedConcreteProviderEventRead>
  );

  const allNewClientReferrals: ConcreteProviderEventRead[] =
    fetchNewClientReferrals.data ? fetchNewClientReferrals.data.data : [];

  const newClientReferralsToDisplay = expandedButton
    ? allNewClientReferrals
    : allNewClientReferrals.slice(0, displayLimit);

  const patientUserIds = new Set<number>();
  if (fetchNewClientReferrals.data) {
    fetchNewClientReferrals.data.data.forEach((referral) => {
      if (referral.patientUserId) {
        patientUserIds.add(referral.patientUserId);
      }
    });
  }

  const patientsByIdQuery = useQuery(
    ['getUsersById', Array.from(patientUserIds).sort()],
    () =>
      Promise.all(
        Array.from(patientUserIds).map((patientId) =>
          UserApi.getUser(patientId)
        )
      ),
    { enabled: patientUserIds.size > 0 }
  );

  const patientsById: Dictionary<UserRead> = keyBy(
    patientsByIdQuery.data ?? [],
    'id'
  );

  const updateShownInSearchMutation = useMutationWithSideEffects(
    (shownInSearch: boolean) => {
      if (isMscSearchEnabled) {
        return Promise.all([
          ProviderApi.updateProviderShownInSearch(provider.id, {
            shownInSearch,
          }),
        ]);
      }
      return Promise.all([
        ProviderLicenseStateApi.updateProviderLicenseState(
          provider.providerLicenseState.id,
          {
            shownInSearch,
          }
        ),
      ]);
    },
    {
      sideEffects: new SideEffectsBuilder<
        ProviderLicenseStateRead[],
        unknown,
        boolean
      >()
        .add({
          onMutate: (shownInSearch) => {
            // Optimistically update local provider
            const prev = provider.providerLicenseState.shownInSearch;

            const showInSearchLicenses =
              getProviderLicenseStatesWhereCanUpdateShownInSearch(provider);
            const updatedProvider = isMscSearchEnabled
              ? {
                  activeProviderLicenseStates:
                    provider.activeProviderLicenseStates.map((pls) =>
                      updateMatchingProviderLicenseStateWithShownInSearchValue(
                        pls,
                        showInSearchLicenses,
                        shownInSearch
                      )
                    ),
                  shownInSearch,
                }
              : {
                  providerLicenseState: {
                    ...provider.providerLicenseState,
                    shownInSearch,
                  },
                };

            AuthStore.setProvider({
              ...provider,
              ...updatedProvider,
            });
            return prev;
          },
          onSuccess: (result, shownInSearch) => {
            const showInSearchLicenses =
              getProviderLicenseStatesWhereCanUpdateShownInSearch(provider);
            const updatedProvider = isMscSearchEnabled
              ? {
                  activeProviderLicenseStates:
                    provider.activeProviderLicenseStates.map((pls, idx) =>
                      updateMatchingProviderLicenseStateWithShownInSearchValue(
                        pls,
                        showInSearchLicenses,
                        shownInSearch
                      )
                    ),
                }
              : {
                  providerLicenseState: result[0],
                };
            AuthStore.setProvider({
              ...provider,
              ...updatedProvider,
            });
            // Close any previous toasts from this mutation
            if (toastCloseRef.current) {
              toastCloseRef.current();
            }
            const close = toasts.add(
              shownInSearch
                ? "You're now accepting new clients"
                : "You're no longer accepting new clients",
              {
                variant: 'positive',
                actionLabel: 'Change in settings',
                onAction: () => {
                  navigate('/settings/referrals');
                },
              }
            );
            toastCloseRef.current = close;
            setTimeout(() => {
              close();
            }, 2 * HELIX_TOAST_DEFAULT_TIMEOUT_MS); // ms
            trackEvent({
              name: 'New Client Referral Upcoming Session Banner Toggle Clicked',
              properties: { providerId: provider.id },
            });
          },
          onError: (_error, prev) => {
            AuthStore.setProvider({
              ...provider,
              providerLicenseState: {
                ...provider.providerLicenseState,
                shownInSearch: prev,
              },
            });
          },
        })
        .addErrorLogging(
          () => 'There was a problem toggling accepting new clients'
        ),
    }
  );

  const handleShownInSearchChange = (shownInSearch: boolean) => {
    if (!updateShownInSearchMutation.isLoading) {
      updateShownInSearchMutation.mutate(shownInSearch);
    }
  };

  const isLoading =
    !fetchNewClientReferrals.isFetched || !patientsByIdQuery.isFetched;

  const expandReferrals = () => {
    setExpandedButton(!expandedButton);
  };

  const getSubBodyText = (
    startDate: string | undefined,
    createdOn: string | undefined,
    dob: string | undefined
  ) => {
    const pieces: string[] = [];
    if (startDate) {
      pieces.push(moment(startDate).format('dddd, MMMM Do [at] h:mm a'));
    }
    if (createdOn) {
      pieces.push(`Booked ${moment(createdOn).fromNow()}`);
    }
    if (dob) {
      pieces.push(`${moment().diff(dob, 'years')}yo`);
    }
    return pieces.join(' • ');
  };

  const navigateMenuOptions = (
    key: Key,
    calendarEventLink: string,
    patientUserId: number | undefined,
    providerAppointmentId: number | undefined,
    providerEventId: number,
    providerEventStartDate: string | undefined,
    providerEventEndDate: string | undefined
  ) => {
    trackProviderTaskAnalyticsEvent({
      providerAppointmentId: providerAppointmentId,
      providerEventId: providerEventId,
      providerEventStartDate: providerEventStartDate,
      providerEventEndDate: providerEventEndDate,
      taskCategory: taskCategory,
      ctaButtonCopy: String(key),
      patientUserId: patientUserId,
    });
    if (key === 'reschedule') {
      navigate(`${calendarEventLink}&reschedule=true`);
    } else if (key === 'cancel') {
      navigate(`${calendarEventLink}&cancel=true`);
    } else {
      navigate(`${calendarEventLink}&switch_to_intake_call=true`);
    }
  };

  const shouldShowAcceptingClientsToggle =
    isPayerLevelReferralsEnabled &&
    getCanUpdateShownInSearch(
      provider,
      provider.providerLicenseState,
      isMscSearchEnabled
    ) &&
    // GP non-admins cannot control referrals
    !!hasRateAccess(provider, AuthStore.user);

  const shouldShowCard = allNewClientReferrals.length > 0;

  useEffect(() => {
    if (!shouldShowCard) {
      return;
    }

    trackPageView({
      name: 'New Client Referral Upcoming Session Banner Viewed',
      properties: {
        providerId: provider.id,
        isShownInSearchToggleVisible: shouldShowAcceptingClientsToggle,
      },
    });
  }, [provider.id, shouldShowCard, shouldShowAcceptingClientsToggle]);

  return (
    <>
      {shouldShowCard && (
        <PageSection>
          <div
            css={{
              display: 'grid',
              gap: theme.spacing.x2,
              gridTemplateColumns:
                'minmax(min-content, max-content) max-content minmax(max-content, 1fr) max-content',
              alignItems: 'center',
            }}
          >
            <SectionHeader>New Client Referrals</SectionHeader>
            <Badge variant="positive">
              {allNewClientReferrals.length} upcoming
            </Badge>
            {shouldShowAcceptingClientsToggle && (
              <div
                css={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 0,
                  [theme.__futureMedia.below('tablet')]: {
                    gridRow: 2,
                    gridColumn: '1 / span 4',
                    marginLeft: 'unset',
                  },
                }}
              >
                <Switch
                  name="shownInSearch"
                  labelPosition="left"
                  selected={getShownInSearch(
                    provider,
                    provider.providerLicenseState,
                    isMscSearchEnabled
                  )}
                  onChange={handleShownInSearchChange}
                >
                  Accepting new clients on Headway
                </Switch>
              </div>
            )}
            {allNewClientReferrals.length > displayLimit && (
              <div css={{ marginLeft: theme.spacing.x10, gridColumn: '4' }}>
                <Button variant="link" onPress={expandReferrals}>
                  {expandedButton ? 'Show less' : 'Show more'}
                </Button>
              </div>
            )}
          </div>
          {isLoading ? (
            <TaskLoadingState />
          ) : (
            <ul css={{ padding: 0, margin: 0 }}>
              {newClientReferralsToDisplay.map((referral, index) => {
                const calendarEventLink: string = `/calendar?event_id=${
                  referral.id
                }&start_date=${moment(referral.startDate).toISOString()}`;
                if (!referral.patientUserId) {
                  return null;
                }
                const patient = patientsById[referral.patientUserId];

                if (referral.type === ProviderEventType.INTAKE_CALL) {
                  return (
                    <Task
                      key={index}
                      icon={<PhoneOutlinedIcon />}
                      listHeaderText={
                        <>
                          New phone consultation with{' '}
                          <Link href={`/clients/${referral.patientUserId}`}>
                            {formatPatientName(patient)}
                          </Link>
                        </>
                      }
                      subBodyText={getSubBodyText(
                        referral.startDate,
                        referral.createdOn,
                        patient.dob
                      )}
                      secondaryAction={
                        <MenuTrigger>
                          <Button variant="secondary" size="medium">
                            <div className="flex justify-center">
                              More
                              <IconChevronDown
                                css={{
                                  marginLeft: theme.spacing.x1,
                                  verticalAlign: 'bottom',
                                }}
                              />
                            </div>
                          </Button>
                          <Menu
                            onAction={(key) =>
                              navigateMenuOptions(
                                key,
                                calendarEventLink,
                                referral.patientUserId,
                                referral.providerAppointment?.id,
                                referral.id,
                                referral.startDate,
                                referral.endDate
                              )
                            }
                          >
                            <MenuItem key="Reschedule">Reschedule</MenuItem>
                            <MenuItem key="Cancel">Cancel</MenuItem>
                          </Menu>
                        </MenuTrigger>
                      }
                      primaryAction={
                        <MessageClientButton
                          providerAppointmentId={
                            referral.providerAppointment?.id
                          }
                          providerEventId={referral.id}
                          providerEventStartDate={referral.startDate}
                          providerEventEndDate={referral.endDate}
                          patientUserId={referral.patientUserId}
                          firstName={formatPatientName(patient, {
                            firstNameOnly: true,
                          })}
                        />
                      }
                    />
                  );
                } else {
                  return (
                    <Task
                      key={index}
                      icon={<PersonAddOutlinedIcon />}
                      listHeaderText={
                        <>
                          {providerReferralManagementFlagEnabled &&
                          isPendingReferralAppointment(referral)
                            ? 'Pending'
                            : 'First'}{' '}
                          session with{' '}
                          <Link href={`/clients/${referral.patientUserId}`}>
                            {formatPatientName(patient)}
                          </Link>
                        </>
                      }
                      subBodyText={getSubBodyText(
                        referral.startDate,
                        referral.createdOn,
                        patient.dob
                      )}
                      secondaryAction={
                        <MenuTrigger>
                          <Button variant="secondary" size="medium">
                            <div className="flex justify-center">
                              More
                              <IconChevronDown
                                css={{
                                  marginLeft: theme.spacing.x1,
                                  verticalAlign: 'bottom',
                                }}
                              />
                            </div>
                          </Button>
                          <Menu
                            onAction={(key) =>
                              navigateMenuOptions(
                                key,
                                calendarEventLink,
                                referral.patientUserId,
                                referral.providerAppointment?.id,
                                referral.id,
                                referral.startDate,
                                referral.endDate
                              )
                            }
                          >
                            <MenuItem key="Switch to phone consultation">
                              Switch to phone consultation
                            </MenuItem>
                            <MenuItem key="Reschedule">Reschedule</MenuItem>
                            <MenuItem key="Cancel">Cancel</MenuItem>
                          </Menu>
                        </MenuTrigger>
                      }
                      primaryAction={
                        <MessageClientButton
                          providerAppointmentId={
                            referral.providerAppointment?.id
                          }
                          providerEventId={referral.id}
                          providerEventStartDate={referral.startDate}
                          providerEventEndDate={referral.endDate}
                          patientUserId={referral.patientUserId}
                          firstName={formatPatientName(patient, {
                            firstNameOnly: true,
                          })}
                        />
                      }
                    />
                  );
                }
              })}
            </ul>
          )}
        </PageSection>
      )}
    </>
  );
};

const MessageClientButton = ({
  providerAppointmentId,
  providerEventId,
  providerEventStartDate,
  providerEventEndDate,
  patientUserId,
  firstName,
}: {
  providerAppointmentId: number | undefined;
  providerEventId: number;
  providerEventStartDate: string | undefined;
  providerEventEndDate: string | undefined;
  patientUserId: number;
  firstName: string | undefined;
}) => {
  const buttonText = `Message ${firstName}`;
  return (
    <LinkButton
      href={`/messages?patient=${patientUserId}`}
      variant="primary"
      size="medium"
      onPress={() =>
        trackProviderTaskAnalyticsEvent({
          providerAppointmentId: providerAppointmentId,
          providerEventId: providerEventId,
          providerEventStartDate: providerEventStartDate,
          providerEventEndDate: providerEventEndDate,
          taskCategory: taskCategory,
          ctaButtonCopy: buttonText,
          patientUserId: patientUserId,
        })
      }
    >
      {buttonText}
    </LinkButton>
  );
};
