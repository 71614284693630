import { useProviderPatient } from 'hooks';
import React from 'react';
import {
  ProviderAppointmentReferralStatus,
  useClientReferralStatus,
} from '~/legacy/hooks/useClientReferralStatus';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { PaymentMethodReadinessIssuePreAuthChargeFailedType } from '@headway/api/models/PaymentMethodReadinessIssuePreAuthChargeFailed';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { IconError } from '@headway/helix/icons/Error';
import {
  FAILED_PAYMENTS_M1,
  MM_TREATMENT_PLAN,
  PROVIDER_REFERRAL_MANAGEMENT,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useUserAppointmentReadiness } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { isClientMedicare } from '@headway/shared/utils/insuranceUtils';
import { isUserSelfPayWithRespectToProvider } from '@headway/shared/utils/selfPay';

import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { isEveryFreezeOON } from 'utils/freeze';
import { getMissingPatientSchedulingInfo } from 'utils/patient';

import { isInsuranceStatusAnyOON } from '../Patients/utils/patientInsuranceStatus';
import { getActiveTreatmentPlan } from './TreatmentPlan/TreatmentPlanUtils';

interface BadgeInfo {
  label: string;
  chipType: 'warning' | 'negative' | 'positive' | 'info';
}

const getBadgeInfo = (
  insuranceStatus: PatientInsuranceOrEAPStatus,
  missingPatientVerificationInfo: PatientMissingSchedulingInfoType[],
  freezeReasons: UserFreezeReason[],
  client: UserRead,
  provider: ProviderRead,
  userAppointmentReadiness?: UserAppointmentReadiness,
  treatmentPlans?: ProviderTreatmentPlanRead[],
  isClientMedicareOrMedicaid?: boolean,
  isMMTreatmentPlanRequirementEnabled?: boolean,
  isFailedPaymentsM1Enabled?: boolean,
  insuranceStatusCheckState?: PatientInsuranceOrEAPStatus,
  patientAddresses?: PatientAddressRead[],
  clientReferralStatus?: ProviderAppointmentReferralStatus | null
): BadgeInfo[] | undefined => {
  const hasNoPatientAddresses = !patientAddresses?.length;
  const providerPendingCredentialing =
    insuranceStatus ===
    PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING;
  const patientOutOfNetwork = isInsuranceStatusAnyOON(insuranceStatus);

  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;

  const patientPaymentMethodsAreNotVerified =
    userAppointmentReadiness?.paymentMethod.some(
      (issue) =>
        issue.type ===
        PaymentMethodReadinessIssuePreAuthChargeFailedType.PRE_AUTH_CHARGE_FAILED
    );
  const hasPastAppointmentInTerminalFailure =
    userAppointmentReadiness?.paymentMethod.some(
      (issue) =>
        issue.type ===
        PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
    );

  let isClientVerified: boolean;
  if (isFailedPaymentsM1Enabled) {
    isClientVerified =
      missingPatientVerificationInfo.length === 0 &&
      !patientPaymentMethodsAreNotVerified &&
      !hasPastAppointmentInTerminalFailure;
  } else {
    isClientVerified = missingPatientVerificationInfo.length === 0;
  }

  // self-pay patient with only OON freezes should not actually appear as frozen:
  const isUserSelfPay = isUserSelfPayWithRespectToProvider(client, provider.id);
  const allFreezesOON = isEveryFreezeOON(freezeReasons);

  const isVerifiedWillNeedCredentialing =
    isClientVerified &&
    (isInsuranceStatusAnyOON(insuranceStatusCheckState) ||
      hasNoPatientAddresses) &&
    !isUserSelfPay;

  if (clientReferralStatus === ProviderAppointmentReferralStatus.PENDING) {
    return [
      {
        label: 'Pending referral',
        chipType: 'warning',
      },
    ];
  }

  if (
    clientReferralStatus &&
    clientReferralStatus === ProviderAppointmentReferralStatus.CANCELED
  ) {
    return [
      {
        label: 'Pending referral cancelled',
        chipType: 'warning',
      },
    ];
  }

  if (patientOutOfNetwork) {
    const badges: BadgeInfo[] = [
      {
        label: 'Out of network',
        chipType: 'negative',
      },
    ];
    if (isVerifiedWillNeedCredentialing) {
      badges.push({
        label: 'Missing telehealth address',
        chipType: 'warning',
      });
    }
    return badges;
  }

  if (
    freezeReasons &&
    freezeReasons.length > 0 &&
    !(isUserSelfPay && allFreezesOON)
  ) {
    return [
      {
        label: 'Invalid insurance',
        chipType: 'warning',
      },
    ];
  }

  if (isInNoDataPrelimPricing) {
    return [
      {
        label: 'Verification in progress',
        chipType: 'warning',
      },
    ];
  }

  if (isInOldDataPrelimPricing) {
    return [
      {
        label: 'Verification in progress',
        chipType: 'warning',
      },
    ];
  }

  const badges: BadgeInfo[] = [];

  if (isFailedPaymentsM1Enabled && patientPaymentMethodsAreNotVerified) {
    badges.push({
      label: 'Invalid payment',
      chipType: 'warning',
    });
  }

  if (isFailedPaymentsM1Enabled && hasPastAppointmentInTerminalFailure) {
    badges.push({
      label: 'Failed payment',
      chipType: 'warning',
    });
  }

  // M&M clients
  if (isClientMedicareOrMedicaid) {
    if (isClientMedicare(client)) {
      badges.push({
        label: 'Medicare',
        chipType: 'info',
      });
    } else {
      badges.push({
        label: 'Medicaid',
        chipType: 'info',
      });
    }
  }

  if (isClientVerified) {
    if (providerPendingCredentialing) {
      return [
        {
          label: 'Pending credentialing',
          chipType: 'warning',
        },
      ];
    }

    // If Medicare/Medicaid client is missing an active treatment plan
    if (isClientMedicareOrMedicaid && isMMTreatmentPlanRequirementEnabled) {
      const activeTreatmentPlan = getActiveTreatmentPlan(
        treatmentPlans,
        isMMTreatmentPlanRequirementEnabled
      );

      if (!activeTreatmentPlan) {
        badges.push({
          label: 'Treatment plan required',
          chipType: 'warning',
        });

        return badges;
      }
    }
    if (isVerifiedWillNeedCredentialing) {
      badges.push({
        label: 'Missing telehealth address',
        chipType: 'warning',
      });
    }

    badges.push({
      label: 'Verified for scheduling',
      chipType: 'positive',
    });
  } else {
    const badgeWarnings: BadgeInfo[] = [];

    if (
      missingPatientVerificationInfo.includes(
        PatientMissingSchedulingInfoType.ADDRESS
      )
    ) {
      badgeWarnings.push({
        label: 'Missing address',
        chipType: 'warning',
      });
    }

    if (
      missingPatientVerificationInfo.includes(
        PatientMissingSchedulingInfoType.BILLING
      )
    ) {
      badgeWarnings.push({
        label: 'Missing payment',
        chipType: 'warning',
      });
    }

    if (
      missingPatientVerificationInfo.includes(
        PatientMissingSchedulingInfoType.INSURANCE
      )
    ) {
      badgeWarnings.push({
        label: 'Missing insurance',
        chipType: 'warning',
      });
    }

    if (
      missingPatientVerificationInfo.includes(
        PatientMissingSchedulingInfoType.FORMS
      )
    ) {
      badgeWarnings.push({
        label: 'Missing forms',
        chipType: 'warning',
      });
    }

    badges.push(...badgeWarnings);
  }

  return badges;
};

interface ClientVerificationChipProps {
  client: UserRead;
  claimReadiness?: UserClaimReadinessResponse;
  billingType: BillingType;
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  provider: ProviderRead;
}

type VerificationCheckStatus = 'pending' | 'success' | 'error';

/**
 * Chip that displays whether or not a patient is ready to be seen by a provider (i.e. they have an
 * insurance and payment method active)
 */
export const ClientVerificationChip: React.FC<
  React.PropsWithChildren<ClientVerificationChipProps>
> = ({
  client,
  claimReadiness,
  billingType,
  freezeReasonsByUser,
  provider,
}) => {
  const { insuranceStatus } = useInsuranceStatus(
    client,
    client.activeUserInsurance
  );

  const { isMSCGuardrailWarning } = useMSCGuardrail();
  const { insuranceStatus: insuranceStatusCheckState } = useInsuranceStatus(
    client,
    client.activeUserInsurance,
    true,
    undefined,
    isMSCGuardrailWarning
  );
  const allMissingPatientVerificationInfo = getMissingPatientSchedulingInfo(
    client,
    billingType,
    claimReadiness
  );
  let missingPatientVerificationInfo = [...allMissingPatientVerificationInfo];
  let isClientVerified = missingPatientVerificationInfo.length === 0;

  const isMMTreatmentPlanRequirementEnabled = useFlag(MM_TREATMENT_PLAN, false);
  const isFailedPaymentsM1Enabled = useFlag(FAILED_PAYMENTS_M1, false);

  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: client.id,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });
  const { data: userAppointmentReadiness } = useUserAppointmentReadiness({
    userId: client.id,
    providerId: provider.id,
  });

  const { data: patientAddresses } = usePatientAddresses({
    patientId: client?.id,
  });

  const isMedicareOrMedicaid = useMedicareOrMedicaid(client.id);

  const providerReferralManagementFlagEnabled = useFlag(
    PROVIDER_REFERRAL_MANAGEMENT,
    false
  );

  const { data: clientReferralStatusData } = useClientReferralStatus({
    providerId: provider.id,
    clientId: client.id,
  });
  const clientReferralStatus = clientReferralStatusData?.status;

  const freezeReasons = freezeReasonsByUser[client.id] || [];
  const clientBadges = getBadgeInfo(
    insuranceStatus,
    missingPatientVerificationInfo,
    freezeReasons,
    client,
    provider,
    userAppointmentReadiness,
    treatmentPlans,
    isMedicareOrMedicaid,
    isMMTreatmentPlanRequirementEnabled,
    isFailedPaymentsM1Enabled,
    insuranceStatusCheckState,
    patientAddresses,
    providerReferralManagementFlagEnabled ? clientReferralStatus : null
  );

  const verificationCheckStatus: VerificationCheckStatus =
    isClientVerified && !claimReadiness
      ? 'pending'
      : isClientVerified && claimReadiness?.ok
      ? 'success'
      : 'error';

  if (verificationCheckStatus === 'pending') {
    return null;
  }

  return (
    <div className={'flex items-center'}>
      {clientBadges?.map((badgeInfo, index) => {
        return (
          <div key={index} className={'mr-2'}>
            <Badge
              variant={badgeInfo.chipType}
              icon={badgeInfo.chipType === 'negative' ? IconError : undefined}
            >
              {badgeInfo.label}
            </Badge>
          </div>
        );
      })}
    </div>
  );
};
