import { useFindProviderEvents } from '~/legacy/hooks/useFindProviderEvents';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';

import {
  isCancelledReferralAppointment,
  isPendingReferralAppointment,
  isReferralAppointment,
  patientBecameReadyForReferredAppointment,
} from '../views/Calendar/events/util/events';
import { UseFindProviderEventsQueryKeyArgs } from './useFindProviderEvents';

export const getLastCreatedAppointmentQueryKeyArgs = ({
  providerId,
  clientId,
}: {
  providerId: number;
  clientId: number;
}): UseFindProviderEventsQueryKeyArgs => {
  return {
    expand_estimated_prices: false,
    event_types: [ProviderEventType.APPOINTMENT],
    order_by: 'created_on',
    order: 'desc',
    provider_id: providerId,
    patient_user_id: clientId,
    limit: 1,
  };
};

export enum ProviderAppointmentReferralStatus {
  NOT_A_REFERRAL = 'NOT_A_REFERRAL',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  BECAME_APPOINTMENT_READY = 'BECAME_APPOINTMENT_READY',
}

export type UseClientReferralStatusData = {
  status: ProviderAppointmentReferralStatus | null;
  providerEvent?: ProviderEventRead | null;
};

export const getProviderAppointmentReferralStatusFromProviderEvent = (
  event: ProviderEventRead | null | undefined
) => {
  if (!event) {
    return null;
  }

  if (!isReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.NOT_A_REFERRAL;
  } else if (isPendingReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.PENDING;
  } else if (isCancelledReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.CANCELED;
  } else if (patientBecameReadyForReferredAppointment(event)) {
    return ProviderAppointmentReferralStatus.BECAME_APPOINTMENT_READY;
  } else {
    return null;
  }
};

/**
 * This hook finds the referral status for a given client based on their most recently created appointment.
 * It is a wrapper around the `useFindProviderEvents` hook. It fetches the most recently created appointment
 * for a given provider and patient, and uses that data to determine the client's referral status as well
 * as a few other relevent pieces of data relating to a referral.
 *
 * @returns status: ProviderAppointmentReferralStatus enum value
 * @returns providerEvent: The provider event used to calculate the ProviderAppointmentReferralStatus value
 *                         Note: This is exposed in order to allow other calculations that require event or appointment data
 * @returns isLoading: Whether any of the wrapped queries are loading
 *  */
export const useClientReferralStatus = ({
  providerId,
  clientId,
}: {
  providerId: number;
  clientId: number;
}) => {
  const lastCreatedApptQueryKeyArgs = getLastCreatedAppointmentQueryKeyArgs({
    providerId,
    clientId,
  });

  const clientReferralStatusQuery = useFindProviderEvents(
    lastCreatedApptQueryKeyArgs,
    {
      select: (response): UseClientReferralStatusData | null => {
        const event = response.data[0];
        if (!event) {
          return null;
        }

        return {
          providerEvent: event,
          status: getProviderAppointmentReferralStatusFromProviderEvent(event),
        };
      },
      enabled: !!providerId && !!clientId,
    }
  );

  return clientReferralStatusQuery;
};
