import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { EventFilterFunction } from '.';
import { isPatientOrAutoCancelledReferralAppointment } from '../util/events';

export interface FilterByExcludingCancelledPendingReferralsData {
  excludeCancelledPendingReferrals?: boolean;
  providerUserId?: number;
}

export const filterByExcludingCancelledPendingReferrals: EventFilterFunction<
  FilterByExcludingCancelledPendingReferralsData
> = (
  event: ProviderEventRead,
  filterData: FilterByExcludingCancelledPendingReferralsData
) => {
  if (
    !filterData.excludeCancelledPendingReferrals ||
    !filterData.providerUserId
  ) {
    return false;
  }

  return !!isPatientOrAutoCancelledReferralAppointment(
    event,
    filterData.providerUserId
  );
};
