import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';

export const LOGIN_CREDENTIALS_COLLECTION_TASK_TYPES = new Set([
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_CAQH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY,
]);
