import {
  CheckCircleOutlineTwoTone,
  ErrorOutlineTwoTone,
} from '@mui/icons-material';
import zip from 'lodash/zip';
import moment from 'moment';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { WrapNetwork } from '@headway/api/models/WrapNetwork';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag as fetchFlagValue } from '@headway/shared/FeatureFlags/react';
import {
  findMatchingFrontendCarrier,
  isStatusInNetwork,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { convertListToSentence } from '@headway/shared/utils/strings';
import { theme } from '@headway/ui/theme';

import {
  getCoordinationOfBenefitsFreezeAwaitingUserMessage,
  getCoordinationOfBenefitsFreezeMessage,
  getOtherFreezeMessage,
  getOutOfNetworkFreezeMessage,
  getTermedPlanFreezeMessage,
  getWaivedSessionMaxHitMessage,
  hasCoordinationOfBenefitsAwaitingUserFreeze,
  hasCoordinationOfBenefitsFreeze,
  hasOtherFreeze,
  hasOutOfNetworkFreeze,
  hasTermedPlanFreeze,
  hasWaivedSessionMaxHitFreeze,
} from 'utils/freeze';
import {
  isPatientInNoDataPrelimPricing,
  isPatientInOldDataPrelimPricing,
} from 'utils/prelimPricing';

import { PatientStatusConfig } from './patientStatus';

export const PatientInsuranceStatusToConfigMap: {
  [key in PatientInsuranceOrEAPStatus]: PatientStatusConfig;
} = {
  IN_NETWORK: {
    guidanceCardVariant: 'info',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'positive',
    colorDescriptor: 'success',
    color: theme.color.success,
    chipLabel: 'In network',
    icon: CheckCircleOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) =>
      `We have verified that you can see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} through their insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails
      )}.`,
  },
  IN_NETWORK_PENDING_CREDENTIALING: {
    guidanceCardVariant: 'info',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'positive',
    colorDescriptor: 'success',
    color: theme.color.success,
    chipLabel: 'In network',
    icon: CheckCircleOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[],
      matchingProviderCarrierName?: string
    ) =>
      `We have verified that you will be able to see ${formatPatientName(
        patientUser,
        {
          firstNameOnly: true,
        }
      )} through their insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails
      )} once you are credentialed ${
        isMSCAlphaEnabled
          ? `there`
          : `with ${
              matchingProviderCarrierName ??
              patientUser?.activeUserInsurance?.billingFrontEndCarrierName
            }`
      }.`,
  },
  VIRTUAL_ONLY_NETWORK: {
    guidanceCardVariant: 'info',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'positive',
    colorDescriptor: 'success',
    color: theme.color.success,
    chipLabel: 'Virtual only network',
    icon: CheckCircleOutlineTwoTone,
    getExplanation: (patientUser?: UserRead) =>
      `You’re eligible to see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} via the ${patientUser?.activeUserInsurance
        ?.billingFrontEndCarrierName} virtual network.`,
  },
  MATCHING_FAILED_VERIFICATION: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Unverified',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}’s insurance plan is not active.`,
    bannerCta: 'Update Insurance',
    getExplanation: (patientUser?: UserRead) =>
      `We can't verify ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s insurance — and you can't confirm session details until it's verified. If the insurance details below look correct, our team can manually verify ${formatPatientName(
        patientUser,
        {
          firstNameOnly: true,
        }
      )}'s benefits.`,
  },
  MATCHING_PENDING_VERIFICATION: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Unverified',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s insurance plan has not been verified.`,
    getExplanation: (patientUser?: UserRead) =>
      `We can't verify ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s insurance — and you can't confirm session details until it's verified. If the insurance details below look correct, our team can manually verify ${formatPatientName(
        patientUser,
        {
          firstNameOnly: true,
        }
      )}'s benefits.`,
  },
  NO_INSURANCE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    chipLabel: 'Missing',
    color: theme.color.error,
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) => {
      return `Set up ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s billing method in order to see this client through Headway`;
    },
    bannerCta: 'Setup Billing',
    getExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} doesn’t have insurance information on file. You won’t be able to 
      confirm their sessions until this is added. If you know their insurance, you can add it here.`,
  },
  MATCHING_INCOMPLETE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'error',
    color: theme.color.warning,
    chipLabel: 'Incomplete',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}’s insurance information is incomplete`,
    bannerCta: 'Update Insurance',
    getExplanation: (patientUser?: UserRead) =>
      `We’re missing some details needed to verify ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )}’s insurance plan. You 
      won’t be able to confirm their sessions until this is complete.`,
  },
  NO_DATA_PRELIM_PRICING: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Verification in progress',
    icon: ErrorOutlineTwoTone,
    bannerCta: 'Learn more',
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}’s insurance plan is not verified yet.`,
    getExplanation: (patientUser?: UserRead) =>
      `${
        patientUser?.activeUserInsurance?.billingFrontEndCarrierName ||
        `${formatPatientName(patientUser, {
          firstNameOnly: true,
        })}’s insurer`
      } is working to get us the information we need to verify ${formatPatientName(
        patientUser,
        {
          firstNameOnly: true,
        }
      )}’s benefits.`,
  },
  OLD_DATA_PRELIM_PRICING: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Verification in progress',
    icon: ErrorOutlineTwoTone,
    bannerCta: 'Learn more',
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}’s insurance plan is not verified yet.`,
    getExplanation: (patientUser?: UserRead) =>
      `${
        patientUser?.activeUserInsurance?.billingFrontEndCarrierName ||
        `${formatPatientName(patientUser, {
          firstNameOnly: true,
        })}’s insurer`
      } is working to get us the information we need to verify ${formatPatientName(
        patientUser,
        {
          firstNameOnly: true,
        }
      )}’s benefits.`,
  },
  OUT_OF_NETWORK: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Out of network',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `You are not in-network for ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} and cannot accept their plan.`,
    bannerCta: 'Update Insurance',
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[],
      matchingFrontEndCarrierName?: string,
      isMSCGuardrailWarning?: boolean,
      restrictionDate?: string
    ) =>
      `${
        isMSCGuardrailWarning ? `Starting ${restrictionDate}, y` : `Y`
      }ou won’t be able to see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} through Headway with their current 
      insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails,
        'or'
      )}. If you do have sessions with them, you won’t be reimbursed. If ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )} has 
      a new plan, please update their insurance or let us know.`,
  },
  OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Out of network',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[],
      matchingFrontEndCarrierName?: string,
      isMSCGuardrailWarning?: boolean,
      restrictionDate?: string
    ) =>
      `${
        isMSCGuardrailWarning ? `Starting ${restrictionDate}, y` : `Y`
      }ou won't be able to see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} through their insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails,
        'or'
      )} until you get credentialed with this plan${
        appointmentStateInsuranceStatusDetails &&
        appointmentStateInsuranceStatusDetails.length > 1
          ? ' in those states'
          : appointmentStateInsuranceStatusDetails &&
            appointmentStateInsuranceStatusDetails.length === 1
          ? ' there'
          : ' in the state they are joining from'
      }.`,
  },
  OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Out of network',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[],
      matchingFrontEndCarrierName?: string,
      isMSCGuardrailWarning?: boolean,
      restrictionDate?: string
    ) =>
      `${
        isMSCGuardrailWarning ? `Starting ${restrictionDate}, y` : `Y`
      }ou won't be able to see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} through their insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails,
        'or'
      )} until you get credentialed with this plan${
        appointmentStateInsuranceStatusDetails &&
        appointmentStateInsuranceStatusDetails.length > 1
          ? ' in those states'
          : appointmentStateInsuranceStatusDetails &&
            appointmentStateInsuranceStatusDetails.length === 1
          ? ' there'
          : ' in the state you are seeing them'
      }.`,
  },
  OUT_OF_NETWORK_NOT_LICENSED: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Out of network',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[],
      matchingFrontEndCarrierName?: string,
      isMSCGuardrailWarning?: boolean,
      restrictionDate?: string
    ) =>
      `${
        isMSCGuardrailWarning ? `Starting ${restrictionDate}, y` : `Y`
      }ou won't be able to see ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} through their insurance plan${formatStates(
        isMSCAlphaEnabled,
        appointmentStateInsuranceStatusDetails,
        'or'
      )} until you get credentialed with this plan${
        appointmentStateInsuranceStatusDetails &&
        appointmentStateInsuranceStatusDetails.length > 1
          ? ' in those states'
          : appointmentStateInsuranceStatusDetails &&
            appointmentStateInsuranceStatusDetails.length === 1
          ? ' there'
          : ' in the state they are joining from'
      }.`,
  },
  SECONDARY_INSURANCE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Secondary',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `We need ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s primary insurance details to send claims automatically.`,
    bannerCta: 'Update Insurance',
    getExplanation: (
      patientUser?: UserRead
    ) => `This doesn't look like a primary insurance plan. Update the insurance
      details to match ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s primary plan — this lets us send claims automatically.`,
  },
  COB_FREEZE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Coordination of Benefits',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getCoordinationOfBenefitsFreezeMessage(patientUser),
  },
  COB_FREEZE_AWAITING_USER: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Coordination of Benefits',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getCoordinationOfBenefitsFreezeAwaitingUserMessage(patientUser),
  },
  TERMED_PLAN_FREEZE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Insurance not active',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getTermedPlanFreezeMessage(patientUser),
  },
  WAIVED_SESSION_MAX_HIT_FREEZE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Insurance not active',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getWaivedSessionMaxHitMessage(patientUser),
  },
  OTHER_FREEZE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Inactive insurance',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getOtherFreezeMessage(patientUser),
  },
  OON_FREEZE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Inactive insurance',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => getOutOfNetworkFreezeMessage(patientUser),
  },
  NO_REMAINING_COVERED_SESSIONS: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Inactive insurance',
    icon: ErrorOutlineTwoTone,
    getExplanation: (
      patientUser?: UserRead,
      isMSCAlphaEnabled?: boolean,
      appointmentStateInsuranceStatusDetails?: StatePatientInsuranceStatusDetails[]
    ) => <></>,
  },
};

export function getInsuranceStatus(
  providerCarriers: ProviderFrontEndCarrierRead[],
  carriersById: { [index: string]: FrontEndCarrierRead },
  provider: ProviderRead,
  user?: UserRead,
  userInsurance?: UserInsuranceRead,
  eaps?: InsuranceAuthorizationRead[],
  freezeReasons?: UserFreezeReason[]
): PatientInsuranceOrEAPStatus {
  const userStates =
    user?.patientStates && user?.patientStates.length > 0
      ? user?.patientStates
      : [user?.lastSearchedState];
  const hasValidAnthemEAPAndIsEligible =
    fetchFlagValue(ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP, false) &&
    eaps &&
    eaps.filter(
      (e) => e.frontEndCarrierId === FrontEndCarrierIdentifier.ANTHEM_EAP
    ).length > 0;
  if (hasValidAnthemEAPAndIsEligible) {
    const matchingProviderCarrier = findMatchingFrontendCarrier(
      providerCarriers,
      FrontEndCarrierIdentifier.ANTHEM_EAP,
      undefined,
      undefined,
      undefined,
      false,
      userStates[0]
    );
    return !!matchingProviderCarrier
      ? PatientInsuranceOrEAPStatus.IN_NETWORK
      : PatientInsuranceOrEAPStatus.OUT_OF_NETWORK;
  }
  if (!userInsurance) {
    return PatientInsuranceOrEAPStatus.NO_INSURANCE;
  }

  const providerId = provider.id;

  const isInNoDataPrelimPricing = isPatientInNoDataPrelimPricing(
    userInsurance,
    user,
    providerId
  );

  const isInOldDataPrelimPricing = isPatientInOldDataPrelimPricing(
    userInsurance,
    user,
    providerId
  );

  if (isInNoDataPrelimPricing) {
    return PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  }

  if (isInOldDataPrelimPricing) {
    return PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;
  }

  if (hasCoordinationOfBenefitsFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.COB_FREEZE;
  }

  if (hasCoordinationOfBenefitsAwaitingUserFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.COB_FREEZE_AWAITING_USER;
  }

  if (hasOtherFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.OTHER_FREEZE;
  }

  if (hasOutOfNetworkFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.OON_FREEZE;
  }
  if (hasTermedPlanFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.TERMED_PLAN_FREEZE;
  }
  if (hasWaivedSessionMaxHitFreeze(freezeReasons)) {
    return PatientInsuranceOrEAPStatus.WAIVED_SESSION_MAX_HIT_FREEZE;
  }

  const carrier: FrontEndCarrierRead | undefined =
    !!userInsurance?.billingFrontEndCarrierId
      ? carriersById?.[userInsurance.billingFrontEndCarrierId]
      : undefined;

  let matchingProviderCarrier: ProviderFrontEndCarrierRead | undefined;
  for (const state of userStates) {
    matchingProviderCarrier = findMatchingFrontendCarrier(
      providerCarriers,
      userInsurance?.billingFrontEndCarrierId,
      userInsurance?.correctedMemberId || userInsurance?.memberId,
      userInsurance?.latestEligibilityLookup,
      carrier,
      false,
      state
    );
    if (matchingProviderCarrier) {
      break;
    }
  }

  if (!matchingProviderCarrier) {
    return PatientInsuranceOrEAPStatus.OUT_OF_NETWORK;
  }

  if (
    !(
      userInsurance.memberId &&
      userInsurance.firstName &&
      userInsurance.lastName &&
      userInsurance.dob &&
      userInsurance.billingFrontEndCarrierId
    )
  ) {
    return PatientInsuranceOrEAPStatus.MATCHING_INCOMPLETE;
  }

  const lastSuccessfulLookup = userInsurance.latestEligibilityLookup;
  if (!lastSuccessfulLookup) {
    return PatientInsuranceOrEAPStatus.MATCHING_PENDING_VERIFICATION;
  }

  if (lastSuccessfulLookup?.isSecondary && !lastSuccessfulLookup.isClaimReady) {
    return PatientInsuranceOrEAPStatus.SECONDARY_INSURANCE;
  }

  if (lastSuccessfulLookup.isClaimReady) {
    if (
      inBCBSMAVirtualOnlyNetwork(carrier, matchingProviderCarrier) ||
      inBCBSNJVirtualOnlyNetwork(carrier, matchingProviderCarrier)
    ) {
      return moment(matchingProviderCarrier.credentialedOn) <= moment()
        ? PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK
        : PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING;
    }

    return matchingProviderCarrier.appointmentReadyDate &&
      moment(matchingProviderCarrier.appointmentReadyDate) <= moment()
      ? PatientInsuranceOrEAPStatus.IN_NETWORK
      : PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING;
  }

  if (
    lastSuccessfulLookup.remainingCoveredSessions !== undefined &&
    lastSuccessfulLookup.remainingCoveredSessions <= 0
  ) {
    return PatientInsuranceOrEAPStatus.NO_REMAINING_COVERED_SESSIONS;
  }

  return PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION;
}

/*
  A patient is being seen through the virtual only network if the patient has 
  BCBSMA insurance and the provider is in the wrap network 
  (this ensures that the provider is not located in Massachusetts). 
*/
export function inBCBSMAVirtualOnlyNetwork(
  carrier: FrontEndCarrierRead | undefined,
  matchingProviderCarrier: ProviderFrontEndCarrierRead
) {
  return (
    carrier?.id ===
      FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS &&
    matchingProviderCarrier.wrapNetwork === WrapNetwork.BCBS_MA
  );
}

/*
  A patient is being seen through the virtual only network if the patient has
  BCBSNJ insurance and the provider is in the wrap network
  (this ensures that the provider is not located in New Jersey).
*/
export function inBCBSNJVirtualOnlyNetwork(
  carrier: FrontEndCarrierRead | undefined,
  matchingProviderCarrier: ProviderFrontEndCarrierRead
) {
  return (
    carrier?.id ===
      FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY &&
    matchingProviderCarrier.wrapNetwork === WrapNetwork.BCBS_NJ
  );
}

export function determineActiveEAPStatus(
  auth: InsuranceAuthorizationRead
): boolean {
  return (
    !!auth.isEapAuthorization &&
    !hasExpirationDatePassed(auth.expirationDate) &&
    validateSessionCount(auth.validSessions) >
      validateSessionCount(auth.pendingSessions) +
        validateSessionCount(auth.usedSessions)
  );
}

export function hasExpirationDatePassed(
  expirationDate: string | undefined
): boolean {
  return moment().isAfter(expirationDate, 'day');
}

export function calculateRemainingSessions(
  auth: InsuranceAuthorizationRead
): number {
  return (
    validateSessionCount(auth.validSessions) -
    validateSessionCount(auth.pendingSessions) -
    validateSessionCount(auth.usedSessions)
  );
}

export function validateSessionCount(sessionNumber: number | undefined) {
  return sessionNumber ? sessionNumber : 0;
}

export type StatePatientInsuranceStatusDetails = {
  state: UnitedStates;
  patientInsuranceStatus: PatientInsuranceOrEAPStatus;
  isTelehealthLocation: boolean;
  isInPersonLocation: boolean;
};

function formatStates(
  isMSCAlphaEnabled?: boolean,
  appointmentStateInsuranceStatusDetailsList?: StatePatientInsuranceStatusDetails[],
  conjunction: string = 'and'
): string {
  if (
    !isMSCAlphaEnabled ||
    !appointmentStateInsuranceStatusDetailsList ||
    appointmentStateInsuranceStatusDetailsList.length === 0
  ) {
    return '';
  }
  const formattedStates: string[] =
    appointmentStateInsuranceStatusDetailsList?.map(formatState) ?? [];
  const formattedSentence = convertListToSentence(formattedStates, conjunction);
  return ` when they're at an address in ${formattedSentence}`;
}

const formatState = (
  statePatientInsuranceStatusDetail: StatePatientInsuranceStatusDetails
): string => {
  const stateDisplayName =
    statesToDisplayNames[statePatientInsuranceStatusDetail.state];

  if (
    !isStatusInNetwork(statePatientInsuranceStatusDetail.patientInsuranceStatus)
  ) {
    return stateDisplayName;
  }

  if (
    statePatientInsuranceStatusDetail.isTelehealthLocation &&
    !statePatientInsuranceStatusDetail.isInPersonLocation
  ) {
    return `${stateDisplayName} (virtual sessions)`;
  }

  if (
    !statePatientInsuranceStatusDetail.isTelehealthLocation &&
    statePatientInsuranceStatusDetail.isInPersonLocation
  ) {
    return `${stateDisplayName} (in-person sessions)`;
  }

  return `${stateDisplayName} (any sessions)`;
};

const NOT_LICENSED_OR_CREDENTIALED_STATUSES = [
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED,
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE,
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE,
];

export function getStatesByPatientInsuranceStatus(
  allStates: UnitedStates[],
  statePatientInsuranceStatuses: PatientInsuranceOrEAPStatus[],
  patientAddressStates: UnitedStates[],
  providerAddressStates: UnitedStates[]
) {
  let statesByPatientInsuranceStatus: {
    [key: string]: StatePatientInsuranceStatusDetails[];
  } = {};
  const addDetailsToStates = (
    key: string,
    details: StatePatientInsuranceStatusDetails
  ) => {
    if (!statesByPatientInsuranceStatus[key]) {
      statesByPatientInsuranceStatus[key] = [];
    }
    statesByPatientInsuranceStatus[key].push(details);
  };

  zip(allStates, statePatientInsuranceStatuses).forEach(
    ([state, statePatientInsuranceStatus]) => {
      if (state && statePatientInsuranceStatus) {
        const key = PatientInsuranceOrEAPStatus[statePatientInsuranceStatus];
        const statePatientInsuranceStatusDetails = {
          state: state,
          patientInsuranceStatus: statePatientInsuranceStatus,
          isTelehealthLocation:
            patientAddressStates.includes(state) &&
            !providerAddressStates.includes(state),
          isInPersonLocation:
            !patientAddressStates.includes(state) &&
            providerAddressStates.includes(state),
        };

        if (NOT_LICENSED_OR_CREDENTIALED_STATUSES.includes(key)) {
          addDetailsToStates(
            'NOT_LICENSED_OR_CREDENTIALED',
            statePatientInsuranceStatusDetails
          );
        } else {
          addDetailsToStates(key, statePatientInsuranceStatusDetails);
        }
      }
    }
  );
  return statesByPatientInsuranceStatus;
}

export function isProviderNotCredentialedInAnyPatientState(
  insuranceStatus?: PatientInsuranceOrEAPStatus,
  patientAddresses?: PatientAddressRead[],
  isTelehealthAppointment?: boolean,
  appointmentState?: UnitedStates,
  isMSCOrGuardrailEnabled: boolean = false
) {
  return (
    (patientAddresses?.length === 0 &&
      isTelehealthAppointment &&
      isMSCOrGuardrailEnabled) ||
    (!appointmentState &&
      (insuranceStatus ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE ||
        insuranceStatus ===
          PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED))
  );
}

export function isProviderNotCredentialedInSpecificAddress(
  insuranceStatus?: PatientInsuranceOrEAPStatus,
  isTelehealthAppointment?: boolean,
  appointmentState?: UnitedStates
) {
  const isOutofNetworkForSelectedVirtualAddress =
    isTelehealthAppointment &&
    appointmentState &&
    (insuranceStatus ===
      PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED ||
      insuranceStatus ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE);
  const isOutofNetworkForSelectedInPersonAddress =
    appointmentState &&
    (insuranceStatus ===
      PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED ||
      insuranceStatus ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE);
  return (
    isOutofNetworkForSelectedVirtualAddress ||
    isOutofNetworkForSelectedInPersonAddress ||
    false
  );
}

export function isInsuranceStatusAnyOON(
  insuranceStatus?: PatientInsuranceOrEAPStatus
) {
  return (
    insuranceStatus === PatientInsuranceOrEAPStatus.OUT_OF_NETWORK ||
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED ||
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE ||
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE
  );
}
