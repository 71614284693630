import { css } from '@emotion/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useProvider } from 'hooks';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ChartReviewItem } from '@headway/api/models/ChartReviewItem';
import { ChartReviewItemStatus } from '@headway/api/models/ChartReviewItemStatus';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderDocumentRemediationCommunicationType } from '@headway/api/models/ProviderDocumentRemediationCommunicationType';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconError } from '@headway/helix/icons/Error';
import { IconShield } from '@headway/helix/icons/Shield';
import { Link } from '@headway/helix/Link';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import { Pagination } from '@headway/helix/Pagination';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useUserList } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { checkExhaustive } from '@headway/shared/utils/types';

import { useFindConcreteProviderEvents } from 'hooks/useFindProviderEvents';
import { usePaginatedProviderDocumentRequestsAndRemediations } from 'hooks/usePaginatedProviderDocumentRequestsAndRemediations';

import {
  isDocumentCompleted,
  isDocumentDueWithinXBusinessDays,
  isDocumentRemediation,
  isDocumentRequest,
} from './utils';

export interface ChartReviewCardProps {
  onEventSelected: (selectedEvent: ConcreteProviderEventRead) => void;
}

export const PAGE_LIMIT = 5;

const getPayers = (
  payersIds: number[] | undefined,
  carriersById: {
    [index: string]: FrontEndCarrierRead;
  }
): string[] => {
  if (!payersIds) return [];
  let payerNames = new Set<string>();
  for (const payerId of payersIds) {
    if (carriersById[payerId]?.name) payerNames.add(carriersById[payerId].name);
  }
  return Array.from(payerNames.values());
};

const createPayerNameString = (payers: string[]) => {
  if (payers.length === 1) {
    return payers[0].includes('Anthem') ? 'Anthem' : payers[0];
  }

  const payerArray = [...payers];
  const lastPayer = payerArray.pop();
  return payerArray.join(', ') + ` and ${lastPayer}`;
};

const getBadgeName = (document: ChartReviewItem) => {
  switch (document.status) {
    case ChartReviewItemStatus.MISSING_DOCUMENT_REQUEST:
      return 'Missing';
    case ChartReviewItemStatus.SUBMITTED:
      return 'Submitted';
    case ChartReviewItemStatus.NEEDS_REVIEW:
      return 'Feedback available';
    case ChartReviewItemStatus.SUBMITTED_WITH_ADDENDUM:
      return 'Submitted with addendum';
    case ChartReviewItemStatus.SUBMITTED_WITHOUT_ADDENDUM:
      return 'Submitted without addendum';
    case ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT:
      return 'Did not pass';
    case ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT:
      return 'Passed';
    case undefined:
      return null;
    default:
      checkExhaustive(document.status);
  }
};

const getButtonText = (document: ChartReviewItem) => {
  return isDocumentRequest(document) && !isDocumentCompleted(document)
    ? 'Upload note'
    : document.status === ChartReviewItemStatus.NEEDS_REVIEW
    ? 'Review feedback'
    : document.status === ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT ||
      document.status === ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT
    ? 'See details'
    : 'View note';
};

const getButtonVariant = (
  document: ChartReviewItem
): 'secondary' | 'link' | 'brand' | 'primary' | undefined => {
  return isDocumentRequest(document) && !isDocumentCompleted(document)
    ? 'primary'
    : document.status === ChartReviewItemStatus.NEEDS_REVIEW
    ? 'primary'
    : document.status === ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT ||
      document.status === ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT
    ? 'secondary'
    : 'secondary';
};

const displayBadge = (document: ChartReviewItem) => {
  return (
    (isDocumentRemediation(document) && !!document.status) ||
    isDocumentRequest(document)
  );
};

const getBadgeColor = (document: ChartReviewItem) => {
  switch (document.status) {
    case ChartReviewItemStatus.MISSING_DOCUMENT_REQUEST:
      return 'warning';
    case ChartReviewItemStatus.SUBMITTED:
      return 'positive';
    case ChartReviewItemStatus.NEEDS_REVIEW:
      return 'warning';
    case ChartReviewItemStatus.SUBMITTED_WITH_ADDENDUM:
      return 'positive';
    case ChartReviewItemStatus.SUBMITTED_WITHOUT_ADDENDUM:
      return 'positive';
    case ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT:
      return 'neutral';
    case ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT:
      return 'positive';
    case undefined:
      throw new Error('Status is undefined');
    default:
      checkExhaustive(document.status);
  }
};

const getCardHelpText = (
  totalRemediationsCount?: number,
  totalCount?: number,
  isMedicareAndMedicaidFeedbackOnly?: boolean
) => {
  if (totalRemediationsCount === undefined || totalCount === undefined)
    return [];

  const helpTexts = new Set<JSX.Element>();
  const addNoteMissingOnlyText = (
    <BodyText>
      <strong>Upload note: </strong>Please upload the requested notes below.
    </BodyText>
  );
  const addNoteText = (
    <BodyText>
      <strong>Upload note: </strong>Please upload the requested notes below. We
      know this is a quick turnaround: we’re here to help. Our templates and
      guidance make it easy to meet insurers’ standards.
    </BodyText>
  );
  const reviewFeedbackText = isMedicareAndMedicaidFeedbackOnly ? (
    <BodyText>
      <div>
        <strong>Next steps: </strong>
        <ol css={{ marginTop: `0 !important`, paddingLeft: theme.spacing.x6 }}>
          <li>Review our feedback for each note linked below.</li>
          <li>Make any required changes and submit as an addendum.</li>
        </ol>
        <br />
        <div>
          As a reminder, your notes must pass this review for your claim to be
          submitted and payment to be processed.
        </div>
      </div>
    </BodyText>
  ) : (
    <BodyText>
      <strong>Review feedback: </strong>We’ve flagged some potential issues for
      you to review. To make the changes, click <strong>Review feedback</strong>
      , add the required info, then submit an addendum.
    </BodyText>
  );

  if (totalRemediationsCount === 0) {
    helpTexts.add(addNoteMissingOnlyText);
  } else if (totalRemediationsCount < totalCount) {
    helpTexts.add(addNoteText);
    helpTexts.add(reviewFeedbackText);
  } else {
    helpTexts.add(reviewFeedbackText);
  }

  return Array.from(helpTexts.values());
};

export interface ShowMoreProps {
  title: string;
  children: React.ReactNode;
}

export const ShowMore = ({ title, children }: ShowMoreProps) => {
  const [showMoreText, setShowMoreText] = useState(false);

  return (
    <>
      <div
        css={{
          display: 'flex',
          marginTop: theme.spacing.x4,
          alignItems: 'center',
        }}
      >
        <BodyText>
          <div
            css={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => setShowMoreText(!showMoreText)}
            onKeyDown={(event) => {
              if (event.code === 'Enter') setShowMoreText(!showMoreText);
            }}
            tabIndex={0}
          >
            <strong>
              <u>{title}</u>
            </strong>
          </div>
        </BodyText>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing.x1,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => setShowMoreText(!showMoreText)}
          onKeyDown={(event) => {
            if (event.code === 'Enter') setShowMoreText(!showMoreText);
          }}
          tabIndex={0}
        >
          {showMoreText ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      {showMoreText && (
        <div css={{ marginTop: theme.spacing.x3 }}>
          <BodyText>{children}</BodyText>
        </div>
      )}
    </>
  );
};

export interface WhatIsThisDescriptionProps {
  payers: string[];
  isMedicareAndMedicaidWithMultiplePprPayers?: boolean;
}

export const WhatIsThisDescription = ({
  payers,
  isMedicareAndMedicaidWithMultiplePprPayers,
}: WhatIsThisDescriptionProps) => {
  const payerName =
    payers && payers.length === 1 && createPayerNameString(payers);
  return (
    <div
      css={{
        padding: theme.spacing.x4,
        backgroundColor: theme.color.system.backgroundGray,
      }}
    >
      <div css={{ ...theme.stack.vertical }}>
        <h3>
          <ListHeader>What is this?</ListHeader>
        </h3>

        <div>
          <div css={{ marginTop: theme.spacing.x4 }}>
            <div>
              <BodyText>
                {payers &&
                payers.length === 1 &&
                !isMedicareAndMedicaidWithMultiplePprPayers
                  ? `${createPayerNameString(
                      payers
                    )} is currently auditing certain documentation before approving claims. `
                  : 'Headway has received requests to gather or review some documentation. '}
                Insurers occasionally request information like progress notes
                and treatment plans to confirm the services they pay for.{' '}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://headway.co/resources/behavioral-health-compliance"
                >
                  We’re here to help
                </Link>
                .
              </BodyText>
            </div>
          </div>
          {payers &&
          payers.length === 1 &&
          !isMedicareAndMedicaidWithMultiplePprPayers ? (
            <ShowMore title="Why Headway is asking for these changes">
              Unlike a traditional billing company, Headway acts as your
              practice partner and shares the responsibility of maintaining
              compliance. To help meet {payerName}'s standards, a dedicated and
              specialized team at Headway will provide feedback on some
              documentation before we send to {payerName}. We only share your
              documentation as allowed or required by HIPAA, according to the
              HIPAA "minimum necessary" rule. {payerName}'s team will then
              review as well.
              <div css={{ marginTop: theme.spacing.x4 }}>
                We expect this process to be temporary, and will only provide
                feedback on notes that don't initially meet {payerName}'s
                requirements.
              </div>
            </ShowMore>
          ) : (
            <ShowMore title="Why is Headway asking for this">
              Unlike a traditional billing company, Headway acts as your
              practice partner and shares the responsibility of maintaining
              compliance. To help meet compliance standards, a dedicated and
              specialized team at Headway will provide feedback on some
              documentation. We only share your documentation as allowed or
              required by HIPAA, according to the HIPAA "minimum necessary"
              rule.
            </ShowMore>
          )}
        </div>
      </div>
    </div>
  );
};

export const ChartReviewCombinedCard = ({
  onEventSelected,
}: ChartReviewCardProps) => {
  const provider = useProvider();
  const { carriersById, isCarriersLoading } = useFrontEndCarriers();
  const [currentPage, setCurrentPage] = useState(0);

  const { data: chartReviewResult } =
    usePaginatedProviderDocumentRequestsAndRemediations({
      providerId: provider.id,
      offset: currentPage * PAGE_LIMIT,
      limit: PAGE_LIMIT,
    });

  const documentRequestsAndRemediations = chartReviewResult?.chartReviewItems;
  const totalChartReviewItems = chartReviewResult?.totalCount;
  const totalChartReviewRemediationsItems =
    chartReviewResult?.totalRemediationsCount;
  const isHeadwayInitiated = chartReviewResult?.isHeadwayChartReview;
  const isMedicareAndMedicaidFeedback =
    chartReviewResult?.isMedicareAndMedicaidFeedback;
  const areAllDocumentsProvided = chartReviewResult?.areAllDocumentsCompleted;
  const shouldHideChartReviewCard =
    chartReviewResult?.shouldHideChartReviewCard;
  const areRequestsOrRemediationsOverdue =
    chartReviewResult?.areRequestsOrRemediationsPastDue;
  const payersIds = chartReviewResult?.payersIds;
  const uniquePprPayerIds =
    documentRequestsAndRemediations?.reduce((set, document) => {
      if (
        document.communicationType ===
          ProviderDocumentRemediationCommunicationType.PPR &&
        document.frontEndCarrierId
      ) {
        set.add(document.frontEndCarrierId);
      }
      return set;
    }, new Set<number>()).size || 0;

  const isMedicareAndMedicaidFeedbackOnly =
    isMedicareAndMedicaidFeedback && !uniquePprPayerIds;
  const isMedicareAndMedicaidWithMultiplePprPayers =
    isMedicareAndMedicaidFeedback && !!uniquePprPayerIds;
  const isMultiplePPrPayersOnly =
    uniquePprPayerIds > 1 && !isMedicareAndMedicaidFeedback;

  const { data: paginatedProviderEvents } = useFindConcreteProviderEvents(
    {
      provider_id: provider.id,
      provider_appointment_ids: documentRequestsAndRemediations
        ?.map((request: ChartReviewItem) => request.providerAppointmentId)
        .sort(),
      limit: documentRequestsAndRemediations?.length,
    },
    { enabled: !!documentRequestsAndRemediations }
  );

  const providerEvents = paginatedProviderEvents?.data;

  const clientQueries = useUserList(
    (providerEvents || []).map((event) => ({
      queryKeyArgs: { userId: event.patientUserId! },
    }))
  );
  const clients = clientQueries.map((query) => query.data);

  if (
    !documentRequestsAndRemediations ||
    documentRequestsAndRemediations.length === 0 ||
    !providerEvents ||
    !clients ||
    shouldHideChartReviewCard
  ) {
    return null;
  }

  const providerEventsByAppointmentId = keyBy(
    providerEvents,
    (event) => event.providerAppointment!.id
  );

  const clientsById = keyBy(
    clients.filter((client) => !!client),
    (client) => client!.id
  );

  const payers = isCarriersLoading ? [] : getPayers(payersIds, carriersById);

  const getHeaderText = () => {
    const onePayerIds = payers && payers.length === 1;

    if (isMedicareAndMedicaidFeedbackOnly) {
      return 'Action required: Medicare / Medicaid readiness';
    } else if (
      isMedicareAndMedicaidWithMultiplePprPayers ||
      isMultiplePPrPayersOnly
    ) {
      return 'Chart review requests';
    } else if ((uniquePprPayerIds === 1 && onePayerIds) || onePayerIds) {
      return `Chart review: ${createPayerNameString(payers)} request`;
    }

    return 'Chart review';
  };

  return (
    <PageSection>
      <div css={chartReviewCardCss.root}>
        <div css={chartReviewCardCss.header}>
          <div
            css={{
              ...theme.stack.horizontal,
              gap: theme.spacing.x3,
              alignItems: 'center',
            }}
          >
            <IconShield color={theme.color.primary.brandGreen} height={24} />
            <SectionHeader>{getHeaderText()}</SectionHeader>
          </div>
          {areRequestsOrRemediationsOverdue && (
            <GuidanceCard variant="error">
              <BodyText>
                <strong>Past due:</strong> Please review as soon as possible.
              </BodyText>
            </GuidanceCard>
          )}
          {isMedicareAndMedicaidFeedbackOnly &&
            !areRequestsOrRemediationsOverdue &&
            !areAllDocumentsProvided && (
              <div
                css={{
                  padding: theme.spacing.x4,
                  backgroundColor: theme.color.system.backgroundGray,
                }}
              >
                <BodyText>
                  Medicare and Medicaid plans have higher compliance standards
                  than most other insurance plans. We've provided note feedback
                  and resources to make sure your notes will meet the
                  requirements.
                </BodyText>
              </div>
            )}
          {areAllDocumentsProvided && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
                padding: theme.spacing.x4,
                backgroundColor: theme.color.system.backgroundGray,
              }}
            >
              <strong>Updates successfully submitted</strong>
              <BodyText>
                Thank you: we've received everything we need and{' '}
                <span css={{ color: theme.color.primary.red }}>
                  we'll handle the rest. If we have any further feedback or
                  updates to share, we'll let you know.
                </span>
              </BodyText>
            </div>
          )}
          {!isMedicareAndMedicaidFeedbackOnly && (
            <WhatIsThisDescription
              payers={payers}
              isMedicareAndMedicaidWithMultiplePprPayers={
                isMedicareAndMedicaidWithMultiplePprPayers
              }
            />
          )}
          {!areAllDocumentsProvided && (
            <div css={{ ...theme.stack.vertical, gap: theme.spacing.x2 }}>
              {getCardHelpText(
                totalChartReviewRemediationsItems,
                totalChartReviewItems,
                isMedicareAndMedicaidFeedbackOnly
              ).map((helpText, idx) => (
                <div key={`help-text-${idx}`}>{helpText}</div>
              ))}
            </div>
          )}
        </div>

        <Table aria-label="Example Table">
          <TableHeader>
            <Column align="left" width={200}>
              Due date
            </Column>
            <Column>Description</Column>
            <Column align="right">{''}</Column>
          </TableHeader>
          <TableBody>
            {documentRequestsAndRemediations.map((document, idx) => {
              const event =
                providerEventsByAppointmentId[document.providerAppointmentId];
              const client =
                event.patientUserId && clientsById[event.patientUserId];

              const startDate = moment(event.startDate).format('M/D/YYYY');
              let deadline = moment(document.deadline).format('ddd, MMMM D');
              let deadlineColor: string = theme.color.system.textBlack;
              if (isDocumentDueWithinXBusinessDays(document, 2)) {
                if (moment().isAfter(document.deadline, 'day')) {
                  deadline = 'Past Due';
                  deadlineColor = theme.color.primary.red;
                } else {
                  if (moment().isSame(document.deadline, 'day')) {
                    deadline = 'Today';
                  }
                  deadlineColor = theme.color.primary.brown;
                }
              }
              const handleCtaClick = () => {
                if (
                  isDocumentRemediation(document) &&
                  !isDocumentCompleted(document)
                ) {
                  trackEvent({
                    name: 'Review Note Button Clicked',
                    properties: {
                      providerId: provider.id,
                      patientUserId: event.patientUserId!,
                      providerAppointmentId: document.providerAppointmentId,
                    },
                  });
                }
                onEventSelected(event);
              };
              if (!client || !event || isCarriersLoading) {
                return (
                  <Row key={`chart-item-${idx}`}>
                    <Cell>
                      <Skeleton />
                    </Cell>
                    <Cell>
                      <Skeleton />
                    </Cell>
                    <Cell>
                      <Skeleton />
                    </Cell>
                  </Row>
                );
              }

              let requestInfo;
              if (
                (isHeadwayInitiated && payers && payers.length > 0) ||
                (payers && payers.length > 1)
              ) {
                if (!document.frontEndCarrierId) {
                  requestInfo = 'Headway chart review';
                } else {
                  requestInfo = `${
                    carriersById[document.frontEndCarrierId]
                      ? carriersById[document.frontEndCarrierId].name
                      : 'Payer'
                  } request`;
                }
              }

              return (
                <Row key={`chart-item-${idx}`}>
                  <Cell>
                    <div
                      css={{
                        color: deadlineColor,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <>
                        {isDocumentCompleted(document) ? (
                          document.status ===
                            ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT ||
                          document.status ===
                            ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT ? (
                            <div>Submitted</div>
                          ) : (
                            <div>-</div>
                          )
                        ) : (
                          <>
                            {isDocumentDueWithinXBusinessDays(document, 2) && (
                              <IconError
                                className="hlx-guidance-card-icon"
                                aria-hidden={true}
                                css={{ marginRight: theme.spacing.x1 }}
                              />
                            )}
                            <div>{deadline}</div>
                          </>
                        )}
                      </>
                    </div>
                  </Cell>
                  <Cell>
                    <div
                      css={{
                        ...theme.stack.horizontal,
                        gap: theme.spacing.x2,
                      }}
                    >
                      <div css={{ ...theme.stack.vertical }}>
                        <div>
                          <Link
                            component={RouterLink}
                            elementType="a"
                            to={`/clients/${client.id}`}
                          >
                            {`${formatPatientName(client, {
                              firstNameOnly: true,
                            })}'s`}
                          </Link>{' '}
                          progress note for session on {startDate}
                        </div>
                        {requestInfo && (
                          <div>
                            <SubBodyText>{requestInfo}</SubBodyText>
                          </div>
                        )}
                      </div>

                      {displayBadge(document) && (
                        <Badge variant={getBadgeColor(document)}>
                          {getBadgeName(document)}
                        </Badge>
                      )}
                    </div>
                  </Cell>
                  <Cell>
                    <div css={chartReviewCardCss.buttonContainer}>
                      <Button
                        variant={getButtonVariant(document)}
                        size="medium"
                        onPress={handleCtaClick}
                      >
                        {getButtonText(document)}
                      </Button>
                    </div>
                  </Cell>
                </Row>
              );
            })}
          </TableBody>
        </Table>

        {totalChartReviewItems && totalChartReviewItems > PAGE_LIMIT && (
          <div css={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              totalPages={Math.ceil(totalChartReviewItems / PAGE_LIMIT)}
              onPageChange={(page) => setCurrentPage(page - 1)}
              page={currentPage + 1}
            />
          </div>
        )}
      </div>
    </PageSection>
  );
};

const chartReviewCardCss = {
  root: css({
    ...theme.reset,
    ...theme.stack.vertical,
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.x4,
  }),
  list: css({
    margin: 0,
    padding: 0,
  }),
  listItem: css({
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, max-content) max-content 1fr',
    gap: theme.spacing.x2,
    minHeight: 32, // Same as Helix button height
    alignItems: 'self-start',
    [theme.responsive.mobile]: {
      gridTemplateColumns: 'minmax(auto, max-content) 1fr',
    },
  }),
  buttonContainer: css({
    gridColumnStart: -1,
    marginLeft: 'auto',
    display: 'grid',
    textAlign: 'center',
    alignSelf: 'center',
    [theme.responsive.mobile]: {
      gridColumn: '1 / span 2',
      width: '100%',
    },
  }),
  completed: css({
    display: 'flex',
    gap: theme.spacing.x2,
    alignItems: 'center',
    svg: {
      color: theme.color.system.green,
      fontSize: 16,
      flexShrink: 0,
    },
  }),
};
