import { useProvider, useProviderPatient } from 'hooks';
import moment from 'moment/moment';
import React, { useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import {
  ProviderAppointmentReferralStatus,
  useClientReferralStatus,
} from '~/legacy/hooks/useClientReferralStatus';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { AllPaymentMethodsExhaustedRetryCycleMetadata } from '@headway/api/models/AllPaymentMethodsExhaustedRetryCycleMetadata';
import { BillingType } from '@headway/api/models/BillingType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import {
  FAILED_PAYMENTS_M1,
  MAX_SESSIONS_EXPERIENCE,
  MULTI_STATE_CREDENTIALING_BETA,
  PROMS_ONLY_RATE_BOOST,
  PROVIDER_REFERRAL_MANAGEMENT,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { usePatientAppointmentReadinessCutoffDate } from '@headway/shared/hooks/usePatientAppointmentReadinessCutoffDate';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { useUserPaymentMethods } from '@headway/shared/hooks/useUserPaymentMethod';
import { trackPageView } from '@headway/shared/utils/analytics';
import {
  getIneligibleLookupTypeFromReadinessIssues,
  hasApproachingRemainingSessions,
  hasManualVerificationRequiredUnreadiness,
  hasNoMentalHealthBenefitsUnreadiness,
  hasNoRemainingSessionsUnreadiness,
  hasNoTelehealthBenefitsUnreadiness,
  hasOONUnreadiness,
  hasRemainingSessions,
  hasTerminatedUnreadiness,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme as legacyTheme } from '@headway/ui/theme';

import { useClaimReadiness } from 'hooks/useClaimReadiness';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { usePatientAssessmentRecurrenceSchedules } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { useProviderPrice } from 'hooks/useProviderPrice';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import {
  wasPatientInNoDataPrelimPricing,
  wasPatientInOldDataPrelimPricing,
} from 'utils/prelimPricing';
import RateBoostGuidanceCard from 'views/Incentives/RateBoostGuidanceCard';
import { PatientInsuranceStatusToConfigMap } from 'views/Patients/utils/patientInsuranceStatus';
import {
  getUserPaymentsStatus,
  getUserPaymentStatus,
  PatientPaymentStatus,
  PatientPaymentStatusToConfigMap,
} from 'views/Patients/utils/patientPaymentStatus';

import {
  appointmentWasCancelledByProvider,
  getReferralEventChannelDisplayName,
} from '../Calendar/events/util/events';
import { InsuranceIneligibilityExplainerModal } from './InsuranceIneligibilityExplainerModal';
import { ClientContext } from './stores/ClientContext';
import {
  TreatmentPlanRequirementClientBanner,
  useTreatmentPlanRequirements,
} from './TreatmentPlan/Requirements/TreatmentPlanRequirements';
import { getActiveTreatmentPlan } from './TreatmentPlan/TreatmentPlanUtils';

export interface ClientAlertsProps {
  clientId: number;
  userAppointmentReadiness?: UserAppointmentReadiness;
  onOpenAddressModal: () => void;
  onOpenBillingMethodModal: () => void;
  onOpenPaymentModal: () => void;
  onOpenNoDataPrelimPricingVerificationInProgressModal: () => void;
  onOpenOldDataPrelimPricingVerificationInProgressModal: () => void;
  onOpenPlanIneligibleAlertModal: () => void;
}

/** Displays various guidance cards based on the state of the client's info. */
export const ClientAlerts = ({
  clientId,
  userAppointmentReadiness,
  onOpenAddressModal,
  onOpenBillingMethodModal,
  onOpenPaymentModal,
  onOpenNoDataPrelimPricingVerificationInProgressModal,
  onOpenOldDataPrelimPricingVerificationInProgressModal,
  onOpenPlanIneligibleAlertModal,
}: ClientAlertsProps) => {
  const { user: providerUser } = useAuthStore();
  const { data: client } = useUser({ userId: clientId });
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const maxSessionsExperience = useFlag(MAX_SESSIONS_EXPERIENCE, false);
  const isFailedPaymentsM1Enabled = useFlag(FAILED_PAYMENTS_M1, false);
  const providerReferralManagementFlagEnabled = useFlag(
    PROVIDER_REFERRAL_MANAGEMENT,
    false
  );
  const { setAlerts } = React.useContext(ClientContext);

  const { data: claimReadiness } = useClaimReadiness({ patientUser: client });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });

  const { isTreatmentPlanRequired, treatmentPlanRequirementType } =
    useTreatmentPlanRequirements(client?.id);

  const {
    data: patientAssessmentRecurrenceSchedule,
    isLoading: isPatientAssessmentRecurrenceScheduleLoading,
  } = usePatientAssessmentRecurrenceSchedules(
    {
      providerPatientId: providerPatient?.id,
    },
    {
      enabled: !!providerPatient?.id,
    }
  );

  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });
  const activeTreatmentPlan = getActiveTreatmentPlan(
    treatmentPlans,
    isTreatmentPlanRequired
  );
  const {
    data: providerEnrollmentSummary,
    isLoading: isEnrollmentSummaryLoading,
  } = useProviderIncentiveProgramEnrollment({
    providerId: provider.id,
  });

  const insurance = client?.activeUserInsurance;
  const { insuranceStatus } = useInsuranceStatus(client, insurance);
  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;
  const { data: providerPriceData, isLoading: isProviderPriceLoading } =
    useProviderPrice(
      { provider, client },
      { enabled: isInOldDataPrelimPricing }
    );
  const billingType = providerPatient?.billingTypeDefault;
  const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } =
    useUserPaymentMethods(clientId);

  const defaultPaymentMethod = paymentMethods?.find(
    (paymentMethod) => paymentMethod.isDefault
  );

  const allPatientPaymentMethodsExhaustedRetryCycle = (
    userAppointmentReadiness?.paymentMethod || []
  ).find(
    (issue) =>
      issue.type ===
      PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
  );
  const sessionConfirmationDateOfAppointmentInTerminalFailure = (
    allPatientPaymentMethodsExhaustedRetryCycle?.metadata as AllPaymentMethodsExhaustedRetryCycleMetadata
  )?.sessionConfirmationDate;

  let paymentStatus: PatientPaymentStatus;

  const showAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    clientId,
    providerPatient?.id,
    undefined
  );

  if (useFlag(FAILED_PAYMENTS_M1, false)) {
    paymentStatus = getUserPaymentsStatus(
      paymentMethods,
      showAnthemEAPExperience.data
    );
  } else {
    paymentStatus = getUserPaymentStatus(
      defaultPaymentMethod,
      showAnthemEAPExperience.data
    );
  }

  const patientAddressRequired =
    claimReadiness?.requirements?.includes(
      UserClaimReadinessCheck.PATIENT_ADDRESS
    ) ?? false;

  const insuranceStatusConfig =
    PatientInsuranceStatusToConfigMap[insuranceStatus];
  const paymentStatusConfig = PatientPaymentStatusToConfigMap[paymentStatus];
  const wasInNoDataPrelimPricing = wasPatientInNoDataPrelimPricing(
    insurance,
    client,
    provider.id
  );
  const wasInOldDataPrelimPricing = wasPatientInOldDataPrelimPricing(
    insurance,
    client,
    provider.id
  );
  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });
  const shouldShowInsuranceStatusBanner =
    !!client &&
    (billingType === BillingType.INSURANCE ||
      isInNoDataPrelimPricing ||
      isInOldDataPrelimPricing) &&
    !!insuranceStatusConfig.getBannerExplanation;

  useEffect(() => {
    if (
      (isInNoDataPrelimPricing || isInOldDataPrelimPricing) &&
      !isProviderPriceLoading &&
      insurance &&
      shouldShowInsuranceStatusBanner
    )
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          providerId: provider.id,
          patientUserId: client.id,
          userInsuranceId: `${insurance.id}`,
          prelimPricingType: `${insurance.prelimPricingType}`,
          copyVariant: '',
          presumptiveAccumulatorsApplied:
            providerPriceData?.priceCalculationInfo?.presumptiveAccumulators ??
            [],
        },
      });
  }, [
    isInNoDataPrelimPricing,
    isInOldDataPrelimPricing,
    isProviderPriceLoading,
    insurance,
    shouldShowInsuranceStatusBanner,
    provider,
    client?.id,
  ]);

  const isTerminatedUnready = hasTerminatedUnreadiness(
    userAppointmentReadiness
  );

  const isNoMentalHealthBenefitsUnready = hasNoMentalHealthBenefitsUnreadiness(
    userAppointmentReadiness
  );

  const isOONUnready = hasOONUnreadiness(userAppointmentReadiness);

  const isManualVerificationUnready = hasManualVerificationRequiredUnreadiness(
    userAppointmentReadiness
  );

  const isNoTelehealthUnready = hasNoTelehealthBenefitsUnreadiness(
    userAppointmentReadiness
  );

  const shouldShowBillingMethodModalDefault =
    useSearchParam('showIneligibilityModal') !== null;

  const hasNoRemainingSessions = hasNoRemainingSessionsUnreadiness(
    userAppointmentReadiness
  );

  const approachingSessionLimit = hasApproachingRemainingSessions(
    userAppointmentReadiness
  );

  const hasSessions = hasRemainingSessions(userAppointmentReadiness);

  const [
    isProviderIneligibilityExplainerModalOpen,
    setIsProviderIneligibilityExplainerModalOpen,
  ] = React.useState<boolean>(shouldShowBillingMethodModalDefault);

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  useEffect(() => {
    if (
      client &&
      insurance &&
      userAppointmentReadiness?.insurance &&
      insuranceStatus ===
        PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION
    ) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: client.id,
          providerId: provider.id,
          userInsuranceId: String(insurance.id),
          eligibilityLookupId: insurance?.latestEligibilityLookup?.id,
          ineligibleLookupType: getIneligibleLookupTypeFromReadinessIssues(
            userAppointmentReadiness?.insurance
          ),
        },
      });
    }
  }, [
    userAppointmentReadiness?.insurance,
    client,
    insurance,
    insuranceStatus,
    provider.id,
    wasInNoDataPrelimPricing,
    wasInOldDataPrelimPricing,
  ]);

  const showTreatmentPlanRequirementBanner =
    isTreatmentPlanRequired && !activeTreatmentPlan;
  const showMissingPatientHomeAddressBanner =
    patientAddressRequired && !isInNoDataPrelimPricing && !isMSCEnabled;
  const isInsuranceStatusNotInNetwork =
    (insuranceStatus ===
      PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION &&
      (wasInNoDataPrelimPricing || wasInOldDataPrelimPricing)) ||
    isTerminatedUnready ||
    isOONUnready ||
    isNoMentalHealthBenefitsUnready ||
    isManualVerificationUnready ||
    shouldShowInsuranceStatusBanner;
  const showPaymentBanner = !!paymentStatusConfig.getBannerExplanation;

  const hasHighPriorityAlerts =
    showTreatmentPlanRequirementBanner ||
    showMissingPatientHomeAddressBanner ||
    isInsuranceStatusNotInNetwork ||
    showPaymentBanner;

  useEffect(() => {
    setAlerts(hasHighPriorityAlerts);
  }, [hasHighPriorityAlerts, setAlerts]);

  // Only want to show incentive banner if there is no other banner showing and client does not have recurring scheduled assessments
  const shouldShowIncentiveProgramBanner =
    !isEnrollmentSummaryLoading &&
    providerEnrollmentSummary?.isProviderEnrolled &&
    !isPatientAssessmentRecurrenceScheduleLoading &&
    patientAssessmentRecurrenceSchedule &&
    patientAssessmentRecurrenceSchedule.length === 0 &&
    !hasHighPriorityAlerts;

  const { data: clientReferralStatusData } = useClientReferralStatus({
    providerId: provider.id,
    clientId: clientId,
  });

  const referralEvent = clientReferralStatusData?.providerEvent;
  const clientReferralStatus = clientReferralStatusData?.status;

  const { cancellationDate: referralCutoffDateString } =
    usePatientAppointmentReadinessCutoffDate(referralEvent?.virtualId);
  const referralCutoffDate = referralCutoffDateString
    ? new Date(referralCutoffDateString)
    : undefined;

  const showPendingReferralBanner =
    providerReferralManagementFlagEnabled &&
    clientReferralStatus === ProviderAppointmentReferralStatus.PENDING;
  const showCancelledReferralBanner =
    providerReferralManagementFlagEnabled &&
    !!clientReferralStatus &&
    clientReferralStatus === ProviderAppointmentReferralStatus.CANCELED;

  // When an appointment is cancelled by provider, we won't show the advance booking window banner,
  // since the advance booking window did not affect the cancellation of the appointment
  const showAdvanceBookingWindowInReferralBanner =
    !!referralEvent &&
    !appointmentWasCancelledByProvider(referralEvent, providerUser.id);

  const bookingSourceString = useMemo(() => {
    const eventDisplayName = getReferralEventChannelDisplayName(
      referralEvent ?? undefined
    );

    if (eventDisplayName) {
      return `booked ${eventDisplayName}`;
    } else {
      return '';
    }
  }, [referralEvent]);
  const referralTimeZone = referralEvent?.timeZone;

  return (
    <>
      {client && showPendingReferralBanner && (
        <GuidanceCard variant="warning">
          <BodyText>
            {client.displayFirstName}{' '}
            {bookingSourceString ? bookingSourceString + ' and ' : ''}has not
            yet completed their onboarding tasks. If this isn’t completed before
            your{' '}
            <Link
              elementType="a"
              component={RouterLink}
              to="/settings/referrals#advanced-booking-window"
            >
              advance booking window
            </Link>{' '}
            {referralCutoffDate
              ? `on ${referralCutoffDate.toLocaleDateString('en-us', {
                  month: 'long',
                  day: 'numeric',
                })} at ${referralCutoffDate.toLocaleTimeString('en-us', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: referralTimeZone,
                  timeZoneName: 'short',
                })}`
              : ''}
            , we’ll remove them from your calendar and client list and invite
            them to rebook.
          </BodyText>
        </GuidanceCard>
      )}
      {client && showCancelledReferralBanner && (
        <GuidanceCard variant="warning">
          <BodyText>
            {client.displayFirstName}{' '}
            {bookingSourceString ? `${bookingSourceString}, but ` : ''}didn’t
            complete their onboarding tasks
            {showAdvanceBookingWindowInReferralBanner && (
              <>
                {' '}
                before your{' '}
                <Link
                  elementType="a"
                  component={RouterLink}
                  to="/settings/referrals#advanced-booking-window"
                >
                  advance booking window
                </Link>
              </>
            )}
            .
          </BodyText>
        </GuidanceCard>
      )}
      {client && showTreatmentPlanRequirementBanner && (
        <TreatmentPlanRequirementClientBanner
          clientId={client.id}
          requirementType={treatmentPlanRequirementType}
        />
      )}
      {client && (
        <InsuranceIneligibilityExplainerModal
          open={isProviderIneligibilityExplainerModalOpen}
          onClose={() => setIsProviderIneligibilityExplainerModalOpen(false)}
          issues={userAppointmentReadiness?.insurance || []}
          client={client}
          provider={provider}
          onClickOpenBillingMethodModal={onOpenBillingMethodModal}
        />
      )}
      {client && showMissingPatientHomeAddressBanner && (
        <GuidanceCard variant="warning">
          <BodyText>
            We’re missing{' '}
            {formatPatientName(client, {
              firstNameOnly: true,
            }) || 'your patient'}
            ’s home address, which we need to submit insurance claims. We won’t
            be able to process sessions or claims until this is done, so you may
            want to hold off for the moment.
          </BodyText>
          <Button size="large" variant="link" onPress={onOpenAddressModal}>
            Add address
          </Button>
        </GuidanceCard>
      )}
      {(wasInNoDataPrelimPricing || wasInOldDataPrelimPricing) &&
      insuranceStatus ===
        PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION ? (
        <GuidanceCard variant="error">
          <BodyText>
            {clientFirstName}’s insurance plan is not eligible for in-network
            care on Headway
          </BodyText>
          <Button
            size="large"
            variant="link"
            onPress={onOpenPlanIneligibleAlertModal}
          >
            Learn more
          </Button>
        </GuidanceCard>
      ) : client && isTerminatedUnready ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            <strong>
              {clientFirstName}'s insurance plan is no longer active.{' '}
            </strong>
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            Learn more.
          </Link>
        </GuidanceCard>
      ) : client && isOONUnready ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            <strong>
              {clientFirstName}'s insurance plan is not eligible for in-network
              care on Headway.{' '}
            </strong>
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            Learn more.
          </Link>
        </GuidanceCard>
      ) : client && isNoMentalHealthBenefitsUnready ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            <strong>
              {clientFirstName}'s insurance plan no longer includes mental
              health benefits.{' '}
            </strong>
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            Learn more.
          </Link>
        </GuidanceCard>
      ) : client && hasSessions && maxSessionsExperience ? (
        <GuidanceCard
          variant="warning"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            Your client has about{' '}
            {insurance?.latestEligibilityLookup?.remainingCoveredSessions}{' '}
            sessions remaining for this plan year.{' '}
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            See details
          </Link>
        </GuidanceCard>
      ) : client && hasNoRemainingSessions && maxSessionsExperience ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            Your client has no remaining sessions for this plan year. If they'd
            like to attend more, ask them if they'd consider using private pay.{' '}
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            See details
          </Link>
        </GuidanceCard>
      ) : client && approachingSessionLimit && maxSessionsExperience ? (
        <GuidanceCard
          variant="warning"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            Your client only has a few sessions remaining covered by their plan.{' '}
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            See details
          </Link>
        </GuidanceCard>
      ) : client && isManualVerificationUnready ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            <strong>
              We can't verify {clientFirstName}'s insurance plan.{' '}
            </strong>
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            Learn more.
          </Link>
        </GuidanceCard>
      ) : client && isNoTelehealthUnready ? (
        <GuidanceCard
          variant="error"
          css={{ marginBottom: legacyTheme.space.sm }}
        >
          <BodyText>
            {clientFirstName}'s insurance let us know that this plan doesn’t
            cover telehealth sessions. You can switch your client to in-person
            sessions or use private pay to continue with telehealth.
          </BodyText>
          <Link
            onClick={() => setIsProviderIneligibilityExplainerModalOpen(true)}
          >
            Learn more.
          </Link>
        </GuidanceCard>
      ) : shouldShowInsuranceStatusBanner ? (
        <GuidanceCard
          layout={insuranceStatusConfig.guidanceCardLayoutForBanner}
          variant="warning"
        >
          <BodyText>
            {insuranceStatusConfig.getBannerExplanation!(client)}
          </BodyText>
          {insuranceStatusConfig.bannerCta && (
            <Button
              size="large"
              variant="link"
              onPress={
                isInNoDataPrelimPricing
                  ? onOpenNoDataPrelimPricingVerificationInProgressModal
                  : isInOldDataPrelimPricing
                  ? onOpenOldDataPrelimPricingVerificationInProgressModal
                  : onOpenBillingMethodModal
              }
            >
              {insuranceStatusConfig.bannerCta}
            </Button>
          )}
        </GuidanceCard>
      ) : null}

      {shouldShowIncentiveProgramBanner && !promsOnlyRateBoostEnabled && (
        <RateBoostGuidanceCard client={client} />
      )}

      {allPatientPaymentMethodsExhaustedRetryCycle &&
        sessionConfirmationDateOfAppointmentInTerminalFailure &&
        isFailedPaymentsM1Enabled && (
          <GuidanceCard variant="error" layout={'vertical'}>
            <div className={'hlx-typography-body'}>
              {clientFirstName} has previously unpaid sessions that need to be
              resolved before you can confirm any of their sessions held after{' '}
              {moment(
                sessionConfirmationDateOfAppointmentInTerminalFailure
              ).format('MMM D, YYYY')}
              . Please ask them to log in to their Billing page to pay the
              balance or add a new payment method.
            </div>
          </GuidanceCard>
        )}

      {client &&
        !isPaymentMethodsLoading &&
        !showAnthemEAPExperience.isLoading &&
        paymentStatusConfig.getBannerExplanation && (
          <GuidanceCard
            layout={paymentStatusConfig.guidanceCardLayoutForBanner}
            variant={paymentStatusConfig.guidanceCardVariant}
          >
            <BodyText>
              {paymentStatusConfig.getBannerExplanation(client)}
            </BodyText>
            {paymentStatusConfig.bannerCta && (
              <Button size="large" variant="link" onPress={onOpenPaymentModal}>
                {paymentStatusConfig.bannerCta}
              </Button>
            )}
          </GuidanceCard>
        )}
    </>
  );
};
