import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

import { ContactInfo } from '@headway/api/models/ContactInfo';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ErrorIconTip } from '@headway/helix/InfoIconTip';
import { theme as legacyTheme } from '@headway/ui/theme';

import { formatDisplayName } from '../BulkPatientPorting';

export const DATA_WARNING_COPY: { [key: string]: string } = {
  INVALID_EMAIL: 'This email address has a formatting error.',
  INVALID_PHONE: 'Phone numbers must include 10 digits.',
  DUPLICATE_EMAIL:
    'This email address is associated with more than one client.',
  VERIFIED_CLIENT: 'This client is already in your Headway caseload.',
};

export const VerifyStep = ({
  clients,
  hasDataWarnings,
  showUserDisplayName,
}: {
  clients: ContactInfo[];
  hasDataWarnings: boolean;
  showUserDisplayName: boolean;
}) => (
  <div>
    <p css={{ paddingBottom: legacyTheme.space.sm }}>
      Review and confirm the details for your {clients.length} clients below. We
      won’t duplicate information if clients are already in our system. If this
      looks incorrect, you can go back and re-upload the details.
    </p>
    {hasDataWarnings && (
      <GuidanceCard variant="warning">
        <BodyText>
          Some client details are invalid or inconsistent and won’t all be saved
          as shown below. You may want to go back and re-upload their details.
        </BodyText>
      </GuidanceCard>
    )}
    <TableContainer
      component={'div'}
      css={{
        height: '315px',
        overflowY: 'auto',
        marginTop: legacyTheme.space.xl2,
      }}
    >
      <Table aria-label="new client list" stickyHeader size="small">
        <TableHead>
          <TableRow>
            {hasDataWarnings && (
              <>
                <TableCell css={{ borderBottom: 'none' }} />
              </>
            )}
            {(showUserDisplayName
              ? ['Legal Name', 'Preferred Name', 'Email', 'Mobile Phone']
              : ['First Name', 'Last Name', 'Email', 'Mobile Phone']
            ).map((header) => (
              <TableCell
                key={header}
                css={{ fontWeight: legacyTheme.fontWeight.bold }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((row, index) => (
            <TableRow key={index}>
              {hasDataWarnings && (
                <>
                  <TableCell
                    css={{
                      borderBottom: 'none',
                      textAlign: 'right',
                      '& .hlx-tooltip-icon-button': {
                        color: 'var(--hlx-color-primary-brown)',
                      },
                    }}
                  >
                    {row.dataWarningTypes &&
                      row.dataWarningTypes.length > 0 && (
                        <ErrorIconTip size="medium">
                          {row.dataWarningTypes.length > 1 ? (
                            <ul>
                              {row.dataWarningTypes.map(
                                (dataWarningType: string) => (
                                  <li>{DATA_WARNING_COPY[dataWarningType]}</li>
                                )
                              )}
                            </ul>
                          ) : (
                            <span>
                              {DATA_WARNING_COPY[row.dataWarningTypes[0]]}
                            </span>
                          )}
                        </ErrorIconTip>
                      )}
                  </TableCell>
                </>
              )}
              {showUserDisplayName ? (
                <>
                  <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                  <TableCell>{`${formatDisplayName(row) ?? '-'}`}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>{' '}
                </>
              )}

              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);
