import { AxiosError, AxiosResponse, Method } from 'axios';
import React from 'react';

import { axios as axiosClient } from '@headway/api/axios.config';
import { UserRead } from '@headway/api/models/UserRead';
import { REFERRAL_SOURCE } from '@headway/shared/constants/referrals';
import { EXTOLE_REFERRAL_PROGRAM } from '@headway/shared/FeatureFlags/flagNames';
import { REFERRAL_OFFER_AMOUNT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import useScript from '@headway/shared/utils/useScript';

export const useReferralProgramIncentiveAmount = () =>
  useFlag(REFERRAL_OFFER_AMOUNT, 350);

const EXTOLE_DOMAIN = 'https://headway.extole.io';

interface ExtoleUserData {
  email?: string;
  first_name?: string;
  last_name?: string;
  /**
   * The user's unique identifier in our system.  This is how Extole will relate their
   * user back to Headway's user.
   */
  partner_user_id: string;
}

function useExtole() {
  const isExtoleEnabled = useFlag(EXTOLE_REFERRAL_PROGRAM, false);

  const scriptStatus = useScript(
    isExtoleEnabled ? `${EXTOLE_DOMAIN}/core.js` : '',
    {
      async: true,
      type: 'text/javascript',
    }
  );

  // @ts-expect-error
  return scriptStatus === 'ready' ? window.extole : null;
}

interface ExtoleOptions {
  name: string;
  element: HTMLElement;
  jwt?: string;
  data:
    | {
        container?: 'test';
      }
    | (ExtoleUserData & { container?: 'test' });
}
/**
 * Returns a callback that can be used to track a lead form conversion in Extole.
 */
function useExtoleLeadFormConversion(): (data: ExtoleOptions['data']) => void {
  const extole = useExtole();

  return React.useCallback(
    (data) => {
      if (!extole) {
        return;
      }

      if (
        (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
        'production'
      ) {
        data.container = 'test';
      }
      extole.createZone({
        name: 'lead_form_submitted',
        data: data,
      });
    },
    [extole]
  );
}

/**
 * Returns a ref that can be attached to an element that you'd like to embed an Extole zone in.
 * If a token is provided, it will be used to authenticate the user with Extole.
 */
function useExtoleEmbed(
  token: string | undefined
): React.RefObject<HTMLDivElement> {
  const extoleZoneRef = React.useRef<HTMLDivElement | null>(null);

  const extole = useExtole();

  React.useEffect(() => {
    if (!extole || !extoleZoneRef.current) {
      return;
    }

    (function (c, b, f, k, a) {
      // @ts-expect-error
      c[b] = c[b] || {};
      // @ts-expect-error
      for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
    })(
      window,
      'extole',
      // @ts-expect-error
      function (c, b) {
        b[c] =
          b[c] ||
          function () {
            b.q.push([c, arguments]);
          };
      },
      ['createZone'],
      0
    );

    const opts: ExtoleOptions = {
      name: 'embedded_microsite',
      element: extoleZoneRef.current,
      jwt: token,
      data: {},
    };

    if (
      (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
      'production'
    ) {
      opts.data.container = 'test';
    }

    extole.createZone(opts);
  }, [extole]);

  return extoleZoneRef;
}

export interface ExtoleUserResponse extends ExtoleUserData {
  profile_picture_url?: string;
  shareable_link: string;
  advocate_code: string;
  rewards: [
    {
      rewardId: string;
      partnerRewardId: string;
      faceValue: string;
      faceValueType: string;
      dateEarned: string;
      rewardType: string;
      partnerRewardSupplierId: string;
      state: string;
    },
  ];
  friends: [
    {
      firstNameOrEmail: string;
      initials: string;
      email: string;
      profilePictureUrl: string;
      status: string;
    },
  ];
}

export interface IEmailDetails {
  subject: string;
  message: string;
}
export interface ExtoleDataResponse {
  program_label: string;
  campaign_id: string;
  links: {
    company_url: string;
    terms_url: string;
    how_it_works_url: string;
  };
  sharing: {
    email: IEmailDetails;
    native: {
      message: string;
    };
    sms: {
      message: string;
    };
  };
  calls_to_action: {
    account_page: {
      message: string;
    };
    confirmation: {
      message: string;
    };
    menu: {
      message: string;
    };
    product: {
      message: string;
    };
  };
  me: ExtoleUserResponse;
}
interface ExtoleResponse {
  event_id: string;
  campaign_id: string;
  data: ExtoleDataResponse;
}

const fetchExtoleUserData = async (token: string): Promise<ExtoleResponse> => {
  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/zones`,
    { event_name: 'advocate_mobile_experience' },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

interface ExtoleTokenResponse {
  access_token: string;
  expires_in: number;
  scopes: [string];
  capabilities: [string];
}

const createOrFetchExtoleToken = async ({
  user,
  jwt,
}: {
  user: UserRead;
  jwt: string;
}): Promise<ExtoleTokenResponse> => {
  // for signed in user
  if (user.email) {
    const response = await axiosClient.post(
      `${EXTOLE_DOMAIN}/api/v5/token`,
      { jwt },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }

  // for anons
  const response = await axiosClient.get(`${EXTOLE_DOMAIN}/api/v4/token`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const _postEvent = async ({
  token,
  data,
}: {
  token: string;
  data: Record<string, unknown>;
}) => {
  const response = await axiosClient.post(`${EXTOLE_DOMAIN}/events`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const postShareEvent = async ({
  token,
  advocateCode,
  email,
}: {
  token: string;
  advocateCode: string;
  email: string;
}) => {
  const data = {
    event_name: 'share',
    data: {
      channel: 'LEAD_FORM',
      'share.advocate_code': advocateCode,
      'share.recipient': email,
    },
  };

  return await _postEvent({ token, data });
};

const postReferProviderEvent = async ({
  token,
  advocateCode,
  email,
  firstName,
  lastName,
  phoneNumber,
  licenseType,
  licenseState,
  campaignId,
  programLabel,
}: {
  token: string;
  advocateCode: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  licenseType: string;
  licenseState: string;
  campaignId: string;
  programLabel: string;
}) => {
  const data = {
    event_name: 'provider_created',
    data: {
      advocatesCode: advocateCode,
      'friend.email': email,
      'friend.first_name': firstName,
      'friend.last_name': lastName,
      'friend.license_state': licenseState, // state abreviation
      'friend.license_type': licenseType,
      'friend.phone': phoneNumber, // '(800) 756-3421',
      labels: programLabel,
      provider_email: email,
      provider_first_name: firstName,
      provider_last_name: lastName,
      provider_license_state: licenseState,
      provider_license_type: licenseType,
      provider_phone: phoneNumber,
      source: 'invite_a_provider:lead_form',
      target: campaignId,
    },
  };
  return await _postEvent({ token, data });
};

const sendReferralEmail = async ({
  token,
  advocateCode,
  email,
  message,
  campaignId,
}: {
  advocateCode: string;
  token: string;
  email: string;
  message?: string;
  campaignId?: string;
}) => {
  const data = {
    subject: '',
    advocate_code: advocateCode,
    data: {
      channel: 'EMAIL',
      target: campaignId,
    },
    message,
    recipient_emails: [email],
  };

  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/email/share/advocate-code/batch`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

const trackCTAEvents = async ({
  eventName,
  token,
  source,
  programLabel,
  referralId,
  channel,
}: {
  eventName: string;
  channel: 'email' | 'direct' | 'sms' | 'link';
  token: string;
  source?: string; // where this event is being called
  programLabel: string;
  referralId?: string;
}) => {
  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/events`,
    {
      event_name: eventName,
      data: {
        source: source ? `${source}:${REFERRAL_SOURCE}` : REFERRAL_SOURCE,
        'share.channel': channel,
        labels: programLabel,
        partner_share_id: referralId,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

const consentToMarketing = async ({
  token,
  consent,
}: {
  token: string;
  consent: boolean;
}) => {
  await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v4/me/parameters`,
    `::headers.x-extole-app=javascript_sdk&type=PRIVATE&parameters.optin=${consent}`,
    {
      headers: {
        accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

export {
  createOrFetchExtoleToken,
  useExtoleEmbed,
  useExtoleLeadFormConversion,
  fetchExtoleUserData,
  postShareEvent,
  postReferProviderEvent,
  sendReferralEmail,
  trackCTAEvents,
  consentToMarketing,
};
