import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

const validationSchema = Yup.object()
  .shape({
    username: Yup.string().required('Please enter your username'),
    password: Yup.string().required('Please enter your password'),
    retypePassword: Yup.string()
      .required('Please retype your password')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })
  .required();

type LoginCredentialsFormValues = Yup.InferType<typeof validationSchema>;

const initialFormValues: LoginCredentialsFormValues = {
  username: '',
  password: '',
  retypePassword: '',
};

export const LoginCredentialsTaskModal = ({
  activeProviderTask,
  onDismiss,
}: {
  activeProviderTask: ProviderTaskRead;
  onDismiss: () => void;
}) => {
  const onSubmit = (values: LoginCredentialsFormValues) => {
    // TODO(sc-312836): actually save login credentials here, and update task to be complete if successful
    onDismiss();
  };
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      title={`Action required: ${activeProviderTask?.title}`}
    >
      <Formik<LoginCredentialsFormValues>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <ModalContent>
              <BodyText>
                <div className="grid gap-y-4">
                  <div>{activeProviderTask?.description}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        activeProviderTask?.statusDescription || ''
                      ),
                    }}
                  ></div>
                </div>
              </BodyText>
              <Form id="login-credentials" className="mt-6">
                <FormControl
                  name="username"
                  component={TextField}
                  label="Username"
                  disabled={formik.isSubmitting}
                />
                <FormControl
                  name="password"
                  component={TextField}
                  label="Password"
                  type="password"
                  disabled={formik.isSubmitting}
                />
                <FormControl
                  name="retypePassword"
                  component={TextField}
                  label="Retype password"
                  type="password"
                  disabled={formik.isSubmitting}
                />
              </Form>
            </ModalContent>
            <ModalFooter>
              <Button
                form="login-credentials"
                disabled={formik.isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};
